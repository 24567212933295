// Load Styles
import './styles/app.scss';

// Load Bootstrap init
import { initBootstrap } from '../common/scss/bootstrap.js';

import VanillaRouter from './router/vanillarouter.js';
import globals from '../common/utils/globals.js';
import { handleLogin } from '../common/utils/loginhandler.js';
import { handleAuthentication } from '../common/utils/authhandler.js';
import { onlineConnection } from '../common/utils/connectionhandler.js';
import { logStatus, displayError } from '../common/utils/statushandler.js';
import { isObjectEmpty } from '../common/utils/general.js';

import { validateToken } from '../common/data/auth.js';
import { getUnits } from '../common/data/handleunitdata.js';

import { handleError } from './ui/components/errorhandler.js';
import { showUnitsListScreen } from './ui/unitslistscreen.js';

// window.onload = () => {  
//   'use strict';   
//   console.log(`register`);
//   if ("serviceWorker" in navigator) {
//     console.log(`register1`);
//     navigator.serviceWorker.register("../sw.js");
//   }
// }
//global.selectedUnit = {};

var gIntervalId = 0;

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

document.addEventListener('DOMContentLoaded', async function () {
  const customerToken = validateToken();
  if (customerToken?.valid) {
    handleLogin(customerToken);    
    await loadApp();
  } else {
    const customerToken = await handleAuthentication();
    if (customerToken) {
      // Delay loading of the app after successful authentication
      setTimeout(() => {
        handleLogin(customerToken);
        loadApp();
      }, 1500);
    }
  }
});

//  const handleAuthentication = async () => {
//   const siteType = getSiteType();
//   const urlParams = new URLSearchParams(window.location.search);
//   const verificationToken = urlParams.get('token');

//   if (verificationToken) {
//       await handleVerification(verificationToken);
//   } else {
//       toggleVerifySection(false);  // Show login if there's no token
//       await login();  // Login functionality called here if no verification token is present
//   }
// }

// async function handleVerification(verificationToken) {
//     toggleVerifySection(true);

//     try {
//       const statusElement = document.getElementById('status');
//       const customerToken = await verifyToken(verificationToken);  // Fetch token via the separated function     

//         if (customerToken.valid) {
//             //const customerToken = validateToken(data.jwtToken);

//             if (statusElement) statusElement.textContent = 'Verification successful! Redirecting...';
            
//             setTimeout(() => {

//                 handleLogin(customerToken);
//                 // Load the app
//                 loadApp();
//             }, 1500);
//         } else {
//             if (statusElement) statusElement.textContent = 'Verification failed. Please try again.';
//         }
//     } catch (error) {
//         displayError('Error during verification. Please try again.');
//     }
// }

async function loadApp() {
  try {
    const units = await getUnits();
    main(units);
    setMainInterval(units);
  } catch (error) {
    console.error('Error loading app:', error);
    displayError('Failed to load the application. Please try again.');
  }
}

export async function setMainInterval(units) {

  if (globals.mainIntervalId) {
      clearInterval(globals.mainIntervalId);
  }

  globals.mainIntervalId = setInterval(async () => {
      units = getUnits();
      main(units);
  }, 300000); //5 minutes: 1 minute = 60,000 milliseconds; 30 minutes = 30 * 60000 = 1800000 milliseconds;  
}

function getOnlineStatus() {
  try {  
    window.addEventListener("load", (ev) => {
      ev.preventDefault();
      isOnline(onlineConnection());

      window.addEventListener("online", (e) => {
        e.preventDefault();
        //isOnline(true);              
        isOnline(onlineConnection());  
      });
    
      window.addEventListener("offline", (e) => {
        e.preventDefault();
        isOnline(false);
      });    
    });
  } catch (error) {
      handleError(error, "", "mainJS -> getOnlineStatus");
  }
}

async function main(unitData) {

  try {
    const container = document.getElementById('container');
    if (container) container.style.display = 'block';
      document.getElementById('splashcontainer').style.display = 'none';
      document.getElementById('header').style.display = 'block';
      document.getElementById('footer').style.display = 'block';     
      
      if (isObjectEmpty(globals.selectedUnit) || isObjectEmpty(globals.selectedUnit) == null ) {
          showUnitsListScreen(unitData);            
      } else {
          for (let i = 0; i < unitData.length; i++) {
              if (globals.selectedUnit.imei === unitData[i].imei) {
                  globals.selectedUnit = unitData[i];
                  localStorage.setItem("uPressureTrack", JSON.stringify(unitData[i]));
                  createDetailPage(unitData, unitData[i]);
                  break;
              }
          }            
      }
  } catch (e) {
      console.log('getunitdataJS: main ERROR: ', e);
      handleError(e, globals.token, "main");
  }
}

const showLoginPage = (version) => {

  const headerNavMain = document.getElementById('headernavmain');
  headerNavMain.className ='headernavmain-login d-flex justify-content-center align-items-center';     
  while (headerNavMain.hasChildNodes()) {
    headerNavMain.removeChild(headerNavMain.lastChild);
  }

  const headerLogo = document.createElement('div');
  headerLogo.id = 'headerlogo';
  headerLogo.className = 'headerlogo-login';
  headerNavMain.appendChild(headerLogo);   

  const footerLogin = document.getElementById('diagramtoggle');    
  while (footerLogin.hasChildNodes()) {
    footerLogin.removeChild(footerLogin.lastChild);
  }
  footerLogin.className = 'footermain-login'; // align-items-end d-flex 

  const loginVersion = document.createElement('div');
  loginVersion.className = 'version';
  loginVersion.innerText = `version ${version}`;   

  footerLogin.appendChild(loginVersion);
}



const clearStorage = () => {
  localStorage.removeItem("tePressureTrack");
  localStorage.removeItem("vPressureTrack");
  localStorage.removeItem("uPressureTrack");    
}

export const onSleepSignOut = () => {
  
  window.onfocus = (e) => { 
  //window.addEventListener('focus', (e) => {
    e.preventDefault();
    // logStatus(` event: window.onfocus -> getToken()`);
    //getToken();
  }
  //);

  window.onresize = () => {
    //getToken();
  }

  window.addEventListener("touchend", () => {
    location.reload(true); 
    //getToken();
  });

  // addEventListener('pageshow', () => {
  //   history.replaceState({}, document.title, window.location.pathname);
  //   // called on initial load and first back
  // });

  // function isSafariMobile() {
  //   return navigator && /Safari/.test(navigator.userAgent) && /iPhone|iPad/.test(navigator.userAgent)
  // }

  // let myCustomEvent =
  // navigator.userAgent.match('iPhone') != null ? 'popstate' : 'pageshow';
  // window.on(myCustomEvent, function (e) {
  //   alert(`add this!`); // 'popstate' called on all back events
  // });

  // window.onpageshow = function(event) { //Persisted is false on initial page load, so you can check against it, and if it false, it is your first page load.
  //   if (!event.persisted) {
  //       alert("hello");
  //   }
  // };

  window.addEventListener('pageshow', (e) => { 
    e.preventDefault();
    // logStatus(` event: window.addEventListener PAGESHOW`);
    location.reload(true); 
    //getToken();
  });

  if (gIntervalId) {
    clearInterval(gIntervalId);
    gIntervalId = 0;  
    // logStatus(` onSleepSignOut(): gIntervalId = 0`);        
  }
  const session = wialon.core.Session.getInstance();
  console.log(` onSleepSignOut() => session ${session}`)
  console.log(session);
  // logStatus(` onSleepSignOut() => sessionId ${session.getId()}`)
  if (session && session.getId()) {
      session.logout( 
          (error) => { 
              if (error) {
                  console.log(`mainJS: onSleepSignOut(): wialon.core.Session.getInstance().logout ERROR: ${error} ${wialon.core.Errors.getErrorText(error)}`);
                  // logStatus(` onSleepSignOut() => session.logout ERROR: ${error} ${wialon.core.Errors.getErrorText(error)}`);
                  // handleError(error, "", "onSleepSignOut => session.logout");
                  // clearStorage();
              }
                // logStatus(` onSleepSignOut() => session.logout: Logged OUT success!`);
                location.reload(true);           
          }          
      );
  } else {
      console.log(`mainJS: onSleepSignOut => No Session`);
      // logStatus(` onSleepSignOut => No Session`);
      location.reload(true);
  } 
}

export const signOut = () => {
  try{
 
  if (gIntervalId) {
    clearInterval(gIntervalId);
    gIntervalId = 0;          
  }
	const session = wialon.core.Session.getInstance();
  
	if (session && session.getId()) {
    session.logout( 
		  (error) => { 
        if (error) {
          console.log(`mainJS: signOut => wialon.core.Session.getInstance().logout ERROR: ${error} ${wialon.core.Errors.getErrorText(error)}`); // logout failed, print error
        }
        clearStorage();
        location.reload(true);
      }
    );
  } else {
    clearStorage();
    location.reload(true);
  }
} catch (e){
  console.log(`e: signOut => wialon.core.Session.getInstance().logout ERROR: ${e} `); 
  clearStorage();
  location.reload(true);
}
}

const storeToken = (token) => {  
  if(token === ""){
    localStorage.removeItem("tePressureTrack");
  }
  else {
    localStorage.setItem("tePressureTrack", eString(token));
  }  
  return;
}

function getTokenString() {

  let tString = "";
  const eString = localStorage.getItem("tePressureTrack"); 

  if (eString) {
     tString = deString(eString); 
     globals.currentView = localStorage.getItem("vPressureTrack");  
     globals.selectedUnit = JSON.parse(localStorage.getItem("uPressureTrack"));  
  } 

  return tString;
}

function eString(str) {

  let result = "";
  for (let i = 0; i < str.length; i++) {
    let char = (str.charCodeAt(i) + 32) % 256;
    result += String.fromCharCode(char);
  }  
  return result;

}

function deString(str) {

  let result = "";
  for (let i = 0; i < str.length; i++) {
    let char = (str.charCodeAt(i) - 32 + 256) % 256;
    result += String.fromCharCode(char);
  }
  return result;

}

const router = new VanillaRouter({
  type: 'history',
  routes: {
    '/': 'unitslist',
    '/sensorvaluesdiagram': 'sensorvaluesdiagram',
    '/wheelschematic': 'wheelschematic',
    '/unitmap': 'unitmap'
  }
})
.listen()
.on('route', async e => {e.detail.route, e.detail.url});

function show(html) {
  document.body.innerHTML = html;
}

function unitsListRouteHandler() {
  const content = '<h1>Units List view</h1><p>This is the units list. Wanna see the <a href="/#sensorvaluesdiagram">Sensorvalues Diagram</a> section?';
  show(content);
}

function sensorValuesdiagramRouteHandler() {
  const content = '<h1>This is sensor values diagram</h1><p>diagram... Go <a href="/">Units List</a>?';
  show(content);
}
