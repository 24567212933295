import globals from "./globals.js";

export const displayError = (message) => {
    const statusElement = document.getElementById('status');
    if (statusElement) {
        statusElement.textContent = message;
    }
}
  
export const logStatus = (logText) => {

    const onlineStatus = document.getElementById('appstatus');
    const onlineStatusDiv = document.createElement('div');
    onlineStatusDiv.className = 'appstatus offline';

    const spanTime = document.createElement('span');
    spanTime.innerText = `[${new Date().toLocaleTimeString()} - ${globals.currentView}] `;

    const spanOnlineStatus = document.createElement('span');  
    spanOnlineStatus.innerText = logText;
    onlineStatusDiv.appendChild(spanTime);
    onlineStatusDiv.appendChild(spanOnlineStatus);

    onlineStatus.appendChild(onlineStatusDiv);

    return;
}