let map, marker; 

export const initialiseMap = (mapDiv) => {

    if (map) {
        map.remove(); // This removes the existing map and cleans up event listeners
        map = null; // Reset the map variable so it can be re-initialized
    }

    // create a new map instance
    map = L.map(mapDiv, {
        fullscreenControl: true,
        fullscreenControlOptions: {
            position: 'topleft'
        }
    }).setView([-33.97823, 22.45808], 10);

    // add an OpenStreetMap tile layer
    L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    setTimeout(() => {
        map.invalidateSize();
    }, 100);
}

export const showUnitOnMap = (pos) => { 

    if (map) {
        const customIcon = L.divIcon({
            className: 'map-icon'
        });
        if (marker != null) {
            map.removeLayer(marker);
        }
        marker = L.marker({ lat: pos.latitude, lng: pos.longitude }, { icon: customIcon }).addTo(map);
        marker.setLatLng({ lat: pos.latitude, lng: pos.longitude });
        marker.setIcon(customIcon);
        map.invalidateSize();
        map.setView({ lat: pos.latitude, lng: pos.longitude });
        map.invalidateSize();
    }
}