export class Wheel {
    constructor(props) {
        this.sensorId = props.sensorId;
        this.wheelId = props.wheelId;
        this.currentPressure = parseFloat(props.currentPressure);
        this.minPressure = props.minPressure;
        this.maxPressure = props.maxPressure;
        this.recommendedPressure = props.recommendedPressure;
        this.currentTemperature = props.currentTemperature;
        this.maxTemperature = props.maxTemperature;
        this.currentVoltage = props.currentVoltage;
        this.minVoltage = props.minVoltage;
        this.tyreTread = props.tyreTread;
        this.sensorData = props;

        this.tyreDiv = null;

        this.listeners = {}; // For handling custom event listeners

        // Initialize a visual representation of the wheel
        this.viewMode = props.viewMode || 'WHEELSDIAGRAM';
        this.element = this.createWheelElement();
        this.updateDisplay();
    }

    // Thresholds can be set externally
    setThresholds({ minPressure, maxTemperature }) {
        this.minPressure = minPressure;
        this.maxTemperature = maxTemperature;
    }

    setViewMode(mode) {
        if (this.viewMode !== mode) {
            this.viewMode = mode;
            this.updateDisplay(); // Re-render with the correct mode
        }
    }
    
    toggleViewMode() {
        const newMode = this.viewMode === 'WHEELSDIAGRAM' ? 'SENSORVALUESDIAGRAM' : 'WHEELSDIAGRAM';
        this.setViewMode(newMode);
    }

    //getSensorTypeClass() {
    //     if (this.currentPressure < this.minPressure) {
    //         return (this.currentPressure < (this.recommendedPressure * 0.2)) ? 'tyre wheelblink' : 'tyre tyrered';
    //     } 
    //     if (this.currentTemperature > this.maxTemperature) {
    //         return 'tyre tyreorange';
    //     }
    //     if (this.currentPressure > this.maxPressure) {
    //         return 'tyre tyrepurple';
    //     }
    //     if (this.currentVoltage < this.minVoltage) {
    //         return 'tyre tyreyellow';
    //     }
    //     return 'tyre tyrecorrect';
    // }

    // Method to determine the sensor type class based on thresholds
    getTyreTypeClass() {
        if (this.currentPressure < this.minPressure) {
            return this.currentPressure < (this.recommendedPressure * 0.2) ? 'wheelblink' : 'tyrered';
        } 
        if (this.currentTemperature > this.maxTemperature) {
            return 'tyreorange';
        }
        if (this.currentPressure > this.maxPressure) {
            return 'tyrepurple';
        }
        if (this.currentVoltage < this.minVoltage) {
            return 'tyreyellow';
        }
        return 'tyrecorrect';
    }

    // Method to get the color label based on the status
    getSensorValueTypeClass() {

        const defaultColour = 'pt-black';   


        return defaultColour;
    }

    // Function to create and return the wheel's visual element
    createWheelElement() {
        const wheelDiv = document.createElement('div');
        wheelDiv.id = `wheel${this.sensorId}`;
        wheelDiv.classList.add('wheel');

        const tyreNameDiv = document.createElement('div');
        tyreNameDiv.classList.add('tyrename');
        tyreNameDiv.textContent = `A${parseInt(this.wheelId.slice(1, 3))}-T${this.wheelId.slice(0, 1)}`;
        wheelDiv.appendChild(tyreNameDiv);

        this.tyreDiv = document.createElement('div');
        this.tyreDiv.classList.add('tyre');
        wheelDiv.appendChild(this.tyreDiv);

        wheelDiv.onclick = (e) => {
            e.stopImmediatePropagation();
            this.handleClick();
        };

        this.element = wheelDiv;
        this.updateDisplay();

        return wheelDiv;
    }

    // Method to add wheel element to the DOM at a specific container
    renderWheel() {
        if (!this.element) {
            this.createWheelElement();
        }
        this.tyreDiv.className = 'tyre'; // Reset any existing classes
        this.tyreDiv.classList.add(this.getTyreTypeClass()); // Apply class based on sensor values

        return this.element;
    }

    renderSensorValues() {
        this.element.className = 'tyre';
        this.element.classList.add = this.getTyreTypeClass(); // Add more classes based on the state

        return this.element;
    }

    render(container) {
        container.appendChild(this.element);
    }

    // Method to update sensor data and refresh the display
    updateSensorData(newSensorData) {
        this.sensorData = newSensorData;
        this.updateDisplay(); // Re-evaluate and apply correct classes
    }

    // Method to set the correct class based on sensor data
    updateDisplay() {
        // Reset base class
        //this.tyreDiv.innerHTML = '';  
        while (this.tyreDiv.hasChildNodes()) {
            this.tyreDiv.removeChild(this.tyreDiv.lastChild);
        }
        this.tyreDiv.className = 'tyre';

        if (this.viewMode === 'WHEELSDIAGRAM') {
            this.tyreDiv.classList.add(this.getTyreTypeClass());
        }

        if (this.viewMode === 'SENSORVALUESDIAGRAM') {
            this.tyreDiv.classList.add('sensorvalues');

            // Add pressure, temperature, and voltage divs
            const pressureDiv = document.createElement('div');
            let pressureClass = 'pt-black';
            if (this.currentPressure < this.minPressure || this.currentPressure > this.maxPressure) {
                pressureClass = this.currentPressure < this.minPressure ? 'bg-red' : 'bg-purple';
            }
            pressureDiv.classList.add('sensorvalue', pressureClass);
            pressureDiv.textContent = `${this.currentPressure.toFixed(2)} bar`;
            
            const temperatureDiv = document.createElement('div');
            temperatureDiv.classList.add('sensorvalue', this.currentTemperature > this.maxTemperature ? 'bg-orange' : 'pt-black');
            temperatureDiv.textContent = `${this.currentTemperature} \u2103`;
    
            const voltageDiv = document.createElement('div');
            voltageDiv.classList.add('sensorvalue', this.currentVoltage < this.minVoltage ? 'bg-yellow' : 'pt-black');
            voltageDiv.textContent = `${this.currentVoltage} V`;
    
            this.tyreDiv.appendChild(pressureDiv);
            this.tyreDiv.appendChild(temperatureDiv);
            this.tyreDiv.appendChild(voltageDiv);
        }
    }


    // Click handler - this would open a context menu or additional details for the wheel
    handleClick() {
        console.log(`Wheel ${this.id} clicked`);
        // Open context menu or handle click logic here
    }

    // Dynamically update the sensor type or styles based on conditions
    updateSensorType(sensorType) {
        const tyreDiv = this.element.querySelector('.tyre');
        tyreDiv.className = 'tyre'; // Reset classes
        tyreDiv.classList.add(sensorType); // Add the relevant sensor type class
    }

    // Setters with threshold checking and visual updates
    setPressure(newPressure) {
        this.pressure = newPressure;
        if (newPressure < this.pressureThreshold) {
            this.updateSensorType('low-pressure');
            this.trigger('pressureDrop', { wheelId: this.id, pressure: newPressure });
        } else {
            this.updateSensorType('normal-pressure');
        }
    }

    setTemperature(newTemperature) {
        this.temperature = newTemperature;
        if (newTemperature > this.temperatureThreshold) {
            this.updateSensorType('high-temperature');
            this.trigger('temperatureHigh', { wheelId: this.id, temperature: newTemperature });
        } else {
            this.updateSensorType('normal-temperature');
        }
    }

    // Event management methods (same as before)
    on(event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    }

    trigger(event, data) {
        if (this.listeners[event]) {
            this.listeners[event].forEach(callback => callback(data));
        }
    }
}


