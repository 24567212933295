
import '../styles/app.scss';
import globals from '../../common/utils/globals.js';

import {showUnitsListScreen} from './unitslistscreen.js';
import {showDiagramScreen}  from './diagramscreen.js';
import {showUnitMapScreen} from './unitmapscreen.js';

import { createHeaderExitNav, createHeaderMain } from './components/headers.js';

export const createDetailPage = (units, unit) => {

    //window.scrollTo(0, 0);
    setTimeout(() => {
        window.scroll({ top: -1, left: 0, behavior: "smooth" });    
    }, 10); 
    
    createPageHeader(units, unit);    

    if(globals.wheelReport) { 
        globals.wheelReport = false;
    }

    switch (globals.currentView) {
        case 'UNITMAP':
            globals.currentView = 'UNITMAP';
            localStorage.setItem("vPressureTrack", 'UNITMAP');            
            showUnitMapScreen(unit); 
            break;
        case 'WHEELSDIAGRAM':
            globals.currentView = 'WHEELSDIAGRAM';
            localStorage.setItem("vPressureTrack", 'WHEELSDIAGRAM');
            showDiagramScreen(unit); 
            break;
        case 'SENSORVALUESDIAGRAM':
            globals.currentView ='SENSORVALUESDIAGRAM';
            localStorage.setItem("vPressureTrack", 'SENSORVALUESDIAGRAM');
            showDiagramScreen(unit); 
            break;
        case 'UNITSLIST':
            // globals.currentView='SENSORVALUESDIAGRAM';
            // showSensorValuesDiagramScreen(unit); 
            globals.currentView = 'UNITSLIST';
            localStorage.setItem("vPressureTrack", 'UNITSLIST');
            showUnitsListScreen(units);
            break;
        default:
            showUnitsListScreen(units);
            break;
    }
}

const createPageHeader = (units, unit) => {

    const headerNavMain = document.getElementById('headernavmain');
    headerNavMain.className ='navbar navbar-light bg-light pe-1 flex-nowrap'; // container-fluid p-2
    while (headerNavMain.hasChildNodes()) {
        headerNavMain.removeChild(headerNavMain.lastChild);
    }   

    const backNav = createHeaderBackNav(units, unit);
    const headerMain = createHeaderMain(unit);
    const exitNav = createHeaderExitNav(unit);

    headerNavMain.appendChild(backNav);
    headerNavMain.appendChild(headerMain);
    headerNavMain.appendChild(exitNav);
}

const createHeaderBackNav = (units, unit) => {

    const backNav = document.createElement('div');
    backNav.id = 'headerbacknav';

    const backSVG = document.createElement('svg');   
    backSVG.className = 'imgbutton buttonback'; 

    backNav.appendChild(backSVG);

    backNav.onclick = () => {
        globals.currentView = 'UNITSLIST';
        showUnitsListScreen(units);
        localStorage.setItem("vPressureTrack", 'UNITSLIST');

    }

    return backNav;
};










