
import { getWheelProperties } from '../data/handlesensordata.js'

export const loadUnitStatusDiagram = (unit) => {

    unit.wheelSensors = getWheelProperties(unit.wheelSensors);
    unit.spareSensors = getWheelProperties(unit.spareSensors);

    let unitStatusDiagramDiv = document.getElementById(`unitstatusdiagram${unit.imei}`);
    if (unitStatusDiagramDiv != null) {
        while (unitStatusDiagramDiv.hasChildNodes()) {
            unitStatusDiagramDiv.removeChild(unitStatusDiagramDiv.lastChild);
        }
    } else {
        unitStatusDiagramDiv = document.createElement('div');
    }
    unitStatusDiagramDiv.id = `unitstatusdiagram${unit.imei}`;
    unitStatusDiagramDiv.className = 'statusdiagram';

    displayUnitPressureStatus(unitStatusDiagramDiv, unit);

    return unitStatusDiagramDiv;
}

function displayUnitPressureStatus(unitStatusDiagramDiv, unit) {
    const sensors = [...unit.wheelSensors, ...unit.spareSensors];
    for (let s = 0; s < sensors.length; s++) {
        if (sensors[s].sensorType === 'tyrenosignal') {
            sensors[s].currentPressure = 99;
        }
    }
   sensors.sort(function (s1, s2) {
        if (parseFloat(s1.currentPressure) > parseFloat(s2.currentPressure)) return 1;
        if (parseFloat(s1.currentPressure) < parseFloat(s2.currentPressure)) return -1;
        return 0;
    });
    
    const sensorStatusDivRow = document.createElement('div');
    sensorStatusDivRow.className = 'row';
    for (let s = 0; s < sensors.length; s++) {
            const sensorStatusDiv = createSensorPressureElement(sensors[s]);
            sensorStatusDivRow.appendChild(sensorStatusDiv);
            if (sensors[s].sensorType === 'tyrenosignal') sensors[s].currentPressure = null;
    }
    unitStatusDiagramDiv.appendChild(sensorStatusDivRow);
}

function displayUnitTemperatureStatus(unitStatusDiagramDiv, unit) {
    //set temperaturevalue to -99 for sorting of statusdiagram
    for (let s = 0; s < unit.sensors.length; s++) {
        if (unit.sensors[s].sensorType === 'tyrenosignal') {
            unit.sensors[s].temperatureValue = -99;
        }
    }
    unit.sensors.sort(function (s1, s2) {
        if (parseFloat(s1.temperatureValue) < parseFloat(s2.temperatureValue)) return 1;
        if (parseFloat(s1.temperatureValue) > parseFloat(s2.temperatureValue)) return -1;
        return 0;
    });

    for (let s = 0; s < unit.sensors.length; s++) {
        let sensorStatusDiv = createSensorTemperatureElement(unit.sensors[s]);
        unitStatusDiagramDiv.appendChild(sensorStatusDiv);
        if (unit.sensors[s].sensorType === 'tyrenosignal') unit.sensors[s].temperatureValue = null;
    }
}

function displayUnitVoltageStatus(unitStatusDiagramDiv, unit) {
    //set voltagevalue to 99 for sorting of statusdiagram
    for (let s = 0; s < unit.sensors.length; s++) {
        if (unit.sensors[s].sensorType === 'tyrenosignal') {
            unit.sensors[s].voltageValue = 99;
        }
    }
    unit.sensors.sort(function (s1, s2) {
        if (parseFloat(s1.voltageValue) > parseFloat(s2.voltageValue)) return 1;
        if (parseFloat(s1.voltageValue) < parseFloat(s2.voltageValue)) return -1;
        return 0;
    });

    for (let s = 0; s < unit.sensors.length; s++) {
        let sensorStatusDiv = createSensorVoltageElement(unit.sensors[s]);
        unitStatusDiagramDiv.appendChild(sensorStatusDiv);
        if (unit.sensors[s].sensorType === 'tyrenosignal') unit.sensors[s].voltageValue = null;
    }
}

function createSensorPressureElement(sensor) {

    let sensorPressureDiv = document.createElement('div');
    let sensorvaluesdivclasses = [];
    let pressure = parseFloat(sensor.currentPressure).toFixed(1);
    if (sensor.sensorType === 'tyrenosignal') {
        sensorvaluesdivclasses = ['sensorvaluesstatus', 'bg-gray'];
        pressure = "S";
    }
    else {
        if (sensor.pressureLabelColour === 'green') 
         sensorvaluesdivclasses = ['sensorvaluesstatus', 'bg-black'];
        else
            sensorvaluesdivclasses = ['sensorvaluesstatus', `bg-${sensor.pressureLabelColour}`];
    }
    
    sensorPressureDiv.classList.add(...sensorvaluesdivclasses);
    sensorPressureDiv.innerText = pressure;
    return sensorPressureDiv;
}

function createSensorTemperatureElement(sensor) {

    let sensorTemperatureDiv = document.createElement('div');
    let sensorvaluesdivclasses = [];
    let temperature = parseInt(sensor.temperatureValue);
    if (sensor.sensorType === 'tyrenosignal') {
        sensorvaluesdivclasses = ['sensorvaluesstatus', 'blue'];
        temperature = "S";
    }
    else
        sensorvaluesdivclasses = ['sensorvaluesstatus', sensor.temperatureLabelColour];

    //sensorTemperatureDiv.classList.add(...sensorvaluesdivclasses);
    sensorTemperatureDiv.innerHTML = temperature;
    return sensorTemperatureDiv;
}

function createSensorVoltageElement(sensor) {

    let sensorVoltageDiv = document.createElement('div');
    let sensorvaluesdivclasses = [];
    let voltage = parseFloat(sensor.voltageValue).toFixed(1);
    if (sensor.sensorType === 'tyrenosignal') {
        sensorvaluesdivclasses = ['sensorvaluesstatus', 'blue'];
        voltage = "S";
    }
    else
        sensorvaluesdivclasses = ['sensorvaluesstatus', sensor.voltageLabelColour];

    //sensorVoltageDiv.classList.add(...sensorvaluesdivclasses);
    sensorVoltageDiv.innerText = voltage;
    return sensorVoltageDiv;
}