export const PRIMARY_COLOUR = '#185785';
export const ACCENT_COLOUR = '#aece25';
export const LOW_PRESSURE_COLOUR = '#ad0505';
export const HIGH_PRESSURE_COLOUR = '#320e75';
export const HIGH_TEMPERATURE_COLOUR = '#ff7e25';
export const LOW_VOLTAGE_COLOUR = '#ffce00';

export const SPARE_AXLE = 17;
export const UNPAIRED_AXLE = 18;

export default {
    token: "",
    mainIntervalId: 0,
    units: [],
    selectedUnit: {},
    currentView: 'UNITSLIST',
    wheelReport: false,
    sensorValuesDiagramDateSelected: null
    
}