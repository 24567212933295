import { validateToken } from "./auth.js";

const baseUrl = 'https://cloud-connector-dashboard.restiolabs.com/api';
const token = localStorage.getItem('token');

let customerId = localStorage.getItem('customer');
if (!customerId) {
    const customerToken = validateToken();
    customerId = customerToken?.decodedToken?.user?.customerId;
}

export async function getUnitData() {
    try {   
        //let token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImN1c3RvbWVySWQiOiI2NmIzZDZjNmFhZWEzZTY0MzgxYTNhZTAiLCJlbWFpbCI6InByZXNzdXJldHJhY2tAYWRtaW4uY29tIn0sImlhdCI6MTcyMzYzMTgxMywiZXhwIjoxNzU1MTY3ODEzLCJhdWQiOiJodHRwczovL2Nsb3VkLWNvbm5lY3Rvci1kYXNoYm9hcmQucmVzdGlvbGFicy5jb20iLCJpc3MiOiJjbG91ZC1jb25uZWN0b3ItZGFzaGJvYXJkLnJlc3Rpb2xhYnMuY29tIn0.6BDWhCUaVS5VgPMQ3I4TDyvHvN0ANrwqvLBOYUz57c4`;

        if (!customerId) {
            const customerToken = validateToken();
            customerId = customerToken?.decodedToken?.user?.customerId;
        }        


        const apiUrl = `${baseUrl}/customer/${customerId}/unit?inclChild=true`;
        //const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/customer/66b3d6c6aaea3e64381a3ae0/unit?inclChild=true`;
        
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data.count ? data.results : [];

    } catch (e) {
        //const message = document.getElementById('error-message');
        //message.textContent = "Please log in again your token has expired."
        console.error('unitsJS: getUnitData - ERROR ', e);
    }
}