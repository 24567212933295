import globals from '../utils/globals.js';
import { SPARE_AXLE, UNPAIRED_AXLE } from '../utils/globals.js';
import { addLeadingZero } from '../utils/general.js';
import { formatZuluTime } from '../utils/timeformats.js';
import { isObjectEmpty } from '../utils/general.js';

import { handleError } from '../../mobile/ui/components/errorhandler.js';

import { showUnitsListScreen } from '../../mobile/ui/unitslistscreen.js';

import { createDetailPage } from '../../mobile/ui/detailpage.js';

import { getUnitData } from './units.js';
import { fetchWheelSensorData, fetchLocationSensorData } from './sensors.js';

export async function getUnits() {
    let unitData = await getUnitData();
    let units = await processUnitData(unitData);  
    globals.units = units;
    return units;
}

async function processUnitData(data) {    

    let units = [];
    for (let i = 0; i < data.length; i++) {
        const unit = {};
        unit.timeUpdated = formatZuluTime(data[i].locationData?.recordTimestamp) || "Not available";
        unit.customerId = data[i].customerId;
        unit.imei = data[i].imeiNumber;
        unit.name = data[i].unitName ?? data[i].imeiNumber;
        unit.deviceName = data[i].deviceName;
        unit.deviceType = data[i].unitType;
        unit.gatewayBatteryVoltage = data[i].gateway?.batteryVoltage;
        unit.gatewayPowerSupplyVoltage = data[i].gateway?.externalVoltage;
        unit.sinkId = data[i].sinkId;
        unit.sinkBatteryVoltage = data[i].sinkBatteryVoltage;
        unit.sinkPowerSupplyVoltage = data[i].sinkPowerSupplyVoltage;
        //unit.mobileNo = data[i].ph;
        unit.speed = data[i].speed;
        //unit.position = data[i].pos;
        unit.location = data[i].locationData;
        
        unit.driverName = data[i].driverName;
        unit.driverContactNumber = data[i].driverContactNumber;
        unit.notificationSettings = data[i].notificationSettings;
        unit.axleRanges = data[i].axleRanges || [];
        
        const sensors = await fetchWheelSensorData(data[i].imeiNumber);
        
        // Add properties from axleRanges to wheelSensors
        sensors.forEach(sensor => {
            // Extract the axle number from the wheelId
            const axleNumber = sensor.wheelId.slice(1);

            // Find the corresponding axleRange by axleNumber
            const axleRange = unit.axleRanges.find(range => range.axleNumber === axleNumber);

            // If axleRange is found, add the properties to the wheelSensor
            if (axleRange) {
                sensor.recommendedPressure = axleRange.recommendedPressure;
                sensor.pressureDeviationAllowedLow = axleRange.pressureDeviationAllowedLow;
                sensor.pressureDeviationAllowedHigh = axleRange.pressureDeviationAllowedHigh;
                sensor.minPressure = axleRange.minPressure;
                sensor.maxPressure = axleRange.maxPressure;
                sensor.maxTemperature = axleRange.maxTemperature;
                sensor.minVoltage = 2.5; //axleRange.minVoltage;
            }
            sensor.signal = getSensorSignalStatus(sensor);
        });

        unit.wheelSensors = sensors.filter(item => parseInt(item.wheelId.slice(1, 3)) < SPARE_AXLE);
        unit.spareSensors = sensors.filter(item => parseInt(item.wheelId.slice(1, 3)) === SPARE_AXLE);
        unit.unpairedSensors = sensors.filter(item => parseInt(item.wheelId.slice(1, 3)) === UNPAIRED_AXLE);
        unit.locationSensors = await fetchLocationSensorData(data[i].imeiNumber);        

        calculateUnitFlags(unit);
        unit.unitConfig = getUnitConfig(unit)

        units.push(unit);
    }

    units.sort((a, b) => {
        if (b.blinkFlags !== a.blinkFlags) {
            return b.blinkFlags - a.blinkFlags;
        }
        if (b.redFlags !== a.redFlags) {
            return b.redFlags - a.redFlags;
        }
        if (b.orangeFlags !== a.orangeFlags) {
            return b.orangeFlags - a.orangeFlags;
        }
        if (b.purpleFlags !== a.purpleFlags) {
            return b.purpleFlags - a.purpleFlags;
        }
        if (b.yellowFlags !== a.yellowFlags) {
            return b.yellowFlags - a.yellowFlags;
        }
        return b.grayFlags - a.grayFlags; // Fallback to sorting by gray flags last
    });
    return units;
}

function getSensorSignalStatus(sensor) {
    // Convert the payloadTS to a Date object
    let payloadTS = new Date(sensor.payloadTS);

    // Get current time in South African Standard Time (SAST), which is UTC+2
    let now = new Date();
    //let nowSAST = new Date(now.getTime() + (2 * 60 * 60 * 1000)); // Adjust to SAST

    // Subtract 40 minutes (40 * 60 * 1000 milliseconds) from the current time
    let thresholdTime = new Date(now.getTime() - (40 * 60 * 1000));

    // Compare the payloadTS with the thresholdTime
    if (payloadTS < thresholdTime) {
        return false;
    } else {
        return true;
    }
}

function calculateUnitFlags(unit) {

    unit.grayFlags = 0;
    unit.blinkFlags = 0;    
    unit.redFlags = 0;
    unit.purpleFlags = 0;
    unit.orangeFlags = 0;
    unit.yellowFlags = 0;

    const sensors = [...unit.wheelSensors, ...unit.spareSensors];
    for (let s = 0; s < sensors.length; s++) {
        if (sensors[s].signal) {
            if (parseFloat(sensors[s].currentPressure) > parseFloat(sensors[s].maxPressure))
                unit.purpleFlags++;
            if (sensors[s].wheelId.slice(1, 3) !== SPARE_AXLE.toString() && parseFloat(sensors[s].currentPressure) < parseFloat(sensors[s].recommendedPressure) * 20 / 100)
                unit.blinkFlags++;
            if (parseFloat(sensors[s].currentPressure) < parseFloat(sensors[s].minPressure))
                unit.redFlags++;
            if (parseFloat(sensors[s].currentTemperature) > parseFloat(sensors[s].maxTemperature))
                unit.orangeFlags++;
            if (parseFloat(sensors[s].currentVoltage) < parseFloat(sensors[s].minVoltageValue))
                unit.yellowFlags++;
        } else {
            unit.grayFlags++;
        }
    }
    return unit;
}

function getUnitConfig(unit) {

    let unitConfig = [];

    // Check if wheelSensors is not empty before proceeding
    const wheelSensorUnitNumbers = unit.wheelSensors.length === 0
        ? []
        : [...new Set(unit.wheelSensors.map(item => parseInt(item.wheelId.slice(-1))))];

    let maxUnitNumber = wheelSensorUnitNumbers.length === 0
        ? 0
        : Math.max(...wheelSensorUnitNumbers);

    // Check if locationSensors is not empty before calculating maxLocationSensorUnitNumber
    // const maxLocationSensorUnitNumber = unit.locationSensors.length === 0
    const maxLocationSensorUnitNumber = unit.locationSensors.count === 0
        ? 0
        : Math.max(...[...new Set(unit.locationSensors.map(item => item?.wheelId.slice(-1)))].map(Number));

    // Update maxUnitNumber if maxLocationSensorUnitNumber is greater
    maxUnitNumber = Math.max(maxUnitNumber, maxLocationSensorUnitNumber);

    // Generate unit numbers based on maxUnitNumber
    const unitNumbers = Array.from({ length: maxUnitNumber + 1 }, (_, index) => index.toString()).sort();

    // Populate unitConfig array
    for (let un = 0; un < unitNumbers.length; un++) {
        unitConfig.push(getUnitNumberConfig(unit, unitNumbers[un]));
    }

    return unitConfig;
}

function getUnitNumberConfig(unit, unitNumber) {
    const unitNumberConfig = {};
    unitNumberConfig.unitNumber = unitNumber;
    unitNumberConfig.name = unitNumber === 0 ? `unit ${unitNumber}` : `trailer ${unitNumber}`;
    const axles = getAxles(unit, unitNumber);
    unitNumberConfig.axles = axles;
    
    unitNumberConfig.axleCount = axles.length;
    unitNumberConfig.sensorCount = countAxleSensors(axles);
    return unitNumberConfig;
}

function countAxleSensors(axles) {
    let sensorCount = 0;
    for (let a = 0; a < axles.length; a++) {
        if (axles[a].wheelConfig === 0) sensorCount = sensorCount + 2;
        else sensorCount = sensorCount + 4;
    }
    return sensorCount;
}

function getAxles(unit, unitNumber) {

    // Check if wheelSensors is not empty before proceeding
    const wheelSensorAxles = unit.wheelSensors.length === 0
        ? []
        : [...new Set(unit.wheelSensors
            .filter(item => item.wheelId.slice(-1) === unitNumber.toString())
            .map(item => item.wheelId.slice(1, 3)))];

    let maxAxles = wheelSensorAxles.length === 0
        ? 0
        : Math.max(...wheelSensorAxles.map(Number));

    // Check if locationSensors is not empty before proceeding
    //const locationSensorAxles = unit.locationSensors.length === 0
    const locationSensorAxles = unit.locationSensors.count === 0
        ? []
        : [...new Set(unit.locationSensors
            .filter(item => item.wheelId.slice(-1) === unitNumber.toString())
            .map(item => item.wheelId.slice(1, 3)))];

    const maxLocationSensorAxles = locationSensorAxles.length === 0
        ? 0
        : Math.max(...locationSensorAxles.map(Number));

    // Update maxAxles if maxLocationSensorAxles is greater
    if (maxAxles < maxLocationSensorAxles) maxAxles = maxLocationSensorAxles;

    // Generate axle numbers based on maxAxles
    const axleNumbers = Array.from({ length: maxAxles }, (_, index) => addLeadingZero(index + 1)).sort();

    // Populate axles array
    const axles = [];
    for (let a = 0; a < axleNumbers.length; a++) {
        axles.push(getAxleWheelConfig(unit, unitNumber, axleNumbers[a]));
    }

    return axles;
}

function getAxleWheelConfig(unit, unitNumber, axle) {
    const axleWheelConfig = {};
    axleWheelConfig.axle = axle;
    axleWheelConfig.wheelConfig = getWheelConfig(unit, unitNumber, axle);
    return axleWheelConfig;
}

function getWheelConfig(unit, unitNumber, axle) {
    // Check if locationSensors is not empty before proceeding
    let wheelConfig = unit.locationSensors.count === 0
        ? null
        : unit.locationSensors.filter(s => s.wheelId === `l${axle}${unitNumber}`)[0]?.wheelConfig
        || unit.locationSensors.filter(s => s.wheelId === `r${axle}${unitNumber}`)[0]?.wheelConfig;

    if (wheelConfig == null) {
        wheelConfig = 0;

        // Check if wheelSensors is not empty before proceeding
        const wheels = unit.wheelSensors.length === 0
            ? []
            : [...new Set(unit.wheelSensors
                .filter(item => item.wheelId.slice(-1) === unitNumber && item.wheelId.slice(1, 3) === axle)
                .map(item => item.wheelId.slice(0, 1)))];

        if (wheels.length > 2) {
            wheelConfig = 1;
        } else {
            const wheelsToCheck = ["2", "3"];
            if (wheelsToCheck.some(wheel => wheels.includes(wheel))) {
                wheelConfig = 1;
            }
        }
    }

    return wheelConfig;
}



function getAllUnitsSensorValueFlags(units) {    

    for (let u = 0; u < units.length; u++) {
        let purpleflags = 0, blinkflags = 0, redflags = 0, orangeflags = 0, yellowflags = 0, blueflags = 0;
        for (let s = 0; s < units[u].sensors.length; s++) {
            if (parseFloat(units[u].sensors[s].currentPressure) > parseFloat(units[u].sensors[s].maxPressureValue))
                purpleflags++;
            if (units[u].sensors[s].sensorName.slice(0, 1) !== "9" && parseFloat(units[u].sensors[s].currentPressure) < parseFloat(units[u].sensors[s].manufacturersRecommendedPressure) * 20 / 100)
                blinkflags++;
            if (parseFloat(units[u].sensors[s].currentPressure) < parseFloat(units[u].sensors[s].minPressureValue))
                redflags++;
            if (parseFloat(units[u].sensors[s].temperatureValue) > parseFloat(units[u].sensors[s].maxTemperatureValue))
                orangeflags++;
            if (parseFloat(units[u].sensors[s].voltageValue) < parseFloat(units[u].sensors[s].minVoltageValue))
                yellowflags++;
            if (units[u].sensors[s].currentPressure == null && units[u].sensors[s].temperatureValue == null && units[u].sensors[s].voltageValue == null)
                blueflags++;
        }
        units[u].blueFlags = blueflags;
        units[u].purpleFlags = purpleflags;
        units[u].blinkFlags = blinkflags;
        units[u].redFlags = redflags;
        units[u].orangeFlags = orangeflags;
        units[u].yellowFlags = yellowflags;
    }

    return units;
}

function joinTruckAndTrailers(units) {

    let unitWithTrailers = [...units];
    let unitsToRemove = [];

    for (let i = 0; i < unitWithTrailers.length; i++) {

        unitWithTrailers[i].linkedblueFlags = unitWithTrailers[i].blueFlags;
        unitWithTrailers[i].linkedpurpleFlags = unitWithTrailers[i].purpleFlags;
        unitWithTrailers[i].linkedblinkFlags = unitWithTrailers[i].blinkFlags;
        unitWithTrailers[i].linkedredFlags = unitWithTrailers[i].redFlags;
        unitWithTrailers[i].linkedorangeFlags = unitWithTrailers[i].orangeFlags;
        unitWithTrailers[i].linkedyellowFlags = unitWithTrailers[i].yellowFlags;

        //currently only ONE trailer per unit
        if (unitWithTrailers[i].trailers.length > 0) {
            for (let j = 0; j < units.length; j++) {
                for (let t = 0; t < unitWithTrailers[i].trailers.length; t++) {
                    if (unitWithTrailers[i].trailers[t].imei === units[j].imei) {

                        unitWithTrailers[i].linkedblueFlags = unitWithTrailers[i].linkedblueFlags + units[j].blueFlags;
                        unitWithTrailers[i].linkedpurpleFlags = unitWithTrailers[i].linkedpurpleFlags + units[j].purpleFlags;
                        unitWithTrailers[i].linkedblinkFlags = unitWithTrailers[i].linkedblinkFlags + units[j].blinkFlags;
                        unitWithTrailers[i].linkedredFlags = unitWithTrailers[i].linkedredFlags + units[j].redFlags;
                        unitWithTrailers[i].linkedorangeFlags = unitWithTrailers[i].linkedorangeFlags + units[j].orangeFlags;
                        unitWithTrailers[i].linkedyellowFlags = unitWithTrailers[i].linkedyellowFlags + units[j].yellowFlags;
                        unitsToRemove.push(units[j].imei);
                    }
                }
            }
        }
    }

    for (let i = 0; i < unitWithTrailers.length; i++) {
        for (let j = 0; j < unitsToRemove.length; j++) {
            if (unitWithTrailers[i] && (unitWithTrailers[i].imei === unitsToRemove[j])) {
                unitWithTrailers.splice(i, 1); i--;
            }
        }
    }

    unitWithTrailers.sort(function (a, b) {
        if (a.unitName > b.unitName) return 1;
        if (a.unitName < b.unitName) return -1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedblueFlags > b.linkedblueFlags) return -1;
        if (a.linkedblueFlags < b.linkedblueFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedyellowFlags > b.linkedyellowFlags) return -1;
        if (a.linkedyellowFlags < b.linkedyellowFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedorangeFlags > b.linkedorangeFlags) return -1;
        if (a.linkedorangeFlags < b.linkedorangeFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedpurpleFlags > b.linkedpurpleFlags) return -1;
        if (a.linkedpurpleFlags < b.linkedpurpleFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedredFlags > b.linkedredFlags) return -1;
        if (a.linkedredFlags < b.linkedredFlags) return 1;
        return 0;
    });
    unitWithTrailers.sort(function (a, b) {
        if (a.linkedblinkFlags > b.linkedblinkFlags) return -1;
        if (a.linkedblinkFlags < b.linkedblinkFlags) return 1;
        return 0;
    });

    return unitWithTrailers;
}
