import '../styles/app.scss';

import globals from '../../common/utils/globals.js';

import { handleError } from './components/errorhandler.js';
import { isObjectEmpty } from '../../common/utils/general.js';
import { loadUnitStatusDiagram } from '../../common/components/statusdiagram.js';
import { createDetailPage }  from './detailpage.js';
import { createLastUpdatedElement, createHeaderExitNav } from './components/headers.js';



export const showUnitsListScreen = (units) => {
    //window.scrollTo(0, 0);
    
    createPageHeader(units[0]);
    createPageMain(units);
    createPageFooter();
}

const createPageHeader = (unit) => {

    const headerNavMain = document.getElementById('headernavmain');
    headerNavMain.className ='navbar navbar-light bg-light ps-2 pt-2 pe-2 flex-nowrap'; //navbar navbar-light bg-light
    while (headerNavMain.hasChildNodes()) {
        headerNavMain.removeChild(headerNavMain.lastChild);
    }   

    const backNav = createHeaderBackNav();
    const headerMain = createHeaderMain(unit);
    const exitNav = createHeaderExitNav();

    headerNavMain.appendChild(backNav);
    headerNavMain.appendChild(headerMain);
    headerNavMain.appendChild(exitNav);

};

const createHeaderBackNav = () => {

    const iconNav = document.createElement('div');
    iconNav.id = 'iconnav';

    const iconSVG = document.createElement('svg');   
    iconSVG.className = 'imgbutton icondark'; 

    iconNav.appendChild(iconSVG);
    return iconNav;
};

function createHeaderMain(unit) {

    const headerMain = document.createElement('div');
    headerMain.id = 'headermain';

    const headerLogo = document.createElement('div');   
    headerLogo.className = 'd-flex justify-content-center align-items-center pt-2';     

    const headerLogoSvg = document.createElement('svg');   
    headerLogoSvg.className = 'headerlogo'; 
    headerLogo.appendChild(headerLogoSvg);

    headerMain.appendChild(headerLogo);

    const lastUpdatedHeader = createLastUpdatedElement(unit);
    headerMain.appendChild(lastUpdatedHeader);
    
    return headerMain;
}

const createPageMain = (units) => {

    // setTimeout(() => {
    //     window.scroll({ top: -1, left: 0, behavior: "smooth" });    
    // }, 10); 
    
    const unitsListScreen = document.getElementById('maincontainer');
    //unitsListScreen.scrollIntoView();
    unitsListScreen.className = 'maincontainer';
    //unitsListScreen.classList.remove('container-fluid');
    while (unitsListScreen.hasChildNodes()) {
        unitsListScreen.removeChild(unitsListScreen.lastChild);
    }

    const unitsListFragment = document.createDocumentFragment();

    const unitsListHeader = createUnitsListHeader(units);
    unitsListFragment.appendChild(unitsListHeader);

    let unitsListContainerDiv = document.createElement('div');
    unitsListContainerDiv.id = 'unitslistcontainer';
    unitsListContainerDiv.className = 'unitslistcontainer containersection';
    unitsListFragment.appendChild(unitsListContainerDiv);

    unitsListScreen.appendChild(unitsListFragment);

    for (let i = 0; i < units.length; i++) {
        const unit = units[i];
        const unitDiv = 
        createUnitContainer(unit);
        unitsListContainerDiv.appendChild(unitDiv);

        unitDiv.onclick = function (e) {
            e.stopImmediatePropagation();
            globals.currentView='WHEELSDIAGRAM';
            localStorage.setItem("vPressureTrack", 'WHEELSDIAGRAM');
            globals.selectedUnit = unit;
            localStorage.setItem("uPressureTrack", JSON.stringify(unit));
            createDetailPage(units, unit);            
        };       
    }
}

const createPageFooter = () => {

    const diagramToggle = document.getElementById('diagramtoggle');  
    diagramToggle.className = 'bg-light'; 
    while (diagramToggle.hasChildNodes()) {
        diagramToggle.removeChild(diagramToggle.lastChild);
    }

    const footerButtons = document.getElementById('footerbuttons');    
    while (footerButtons.hasChildNodes()) {
        footerButtons.removeChild(footerButtons.lastChild);
    }
    
};

function createUnitsListHeader(units) {
    try {
        //const unitStatuses = getUnitStatuses(units);
        
        const unitsListHeaderDiv = document.createElement('div');
        unitsListHeaderDiv.id = 'unitslistheader';
        unitsListHeaderDiv.className = 'row pt-3' // d-flex justify-content-center align-items-center

        const correctPressureDiv = document.createElement('div'); 
        correctPressureDiv.id = 'correctpressure';  
        correctPressureDiv.className = 'col';      // d-flex justify-content-center align-items-center

        const correctPressurePercDiv = document.createElement('div');
        correctPressurePercDiv.id = `correctpressurepercentage`;
        correctPressurePercDiv.className = 'metricsummaryperc bg-black';
        const correctPressurePercValueSpan = document.createElement('span');
        //correctPressurePercValueSpan.innerText = unitStatuses.percCorrectPressure;        

        const correctPressurePercLabelSpan = document.createElement('span');
        correctPressurePercLabelSpan.innerText = "%"

        correctPressurePercDiv.appendChild(correctPressurePercValueSpan);
        correctPressurePercDiv.appendChild(correctPressurePercLabelSpan);

        const correctPressureLabelTop = document.createElement('div');
        correctPressureLabelTop.className = 'd-flex justify-content-center fw-bolder'
        correctPressureLabelTop.innerText = "Correct"; //Correct Pressure
        const correctPressureLabelBottom = document.createElement('div');
        correctPressureLabelBottom.className = 'd-flex justify-content-center fw-bolder'
        correctPressureLabelBottom.innerText = "Pressure";

        correctPressureDiv.appendChild(correctPressurePercDiv);
        correctPressureDiv.appendChild(correctPressureLabelTop);
        correctPressureDiv.appendChild(correctPressureLabelBottom);

        const underInflatedDiv = document.createElement('div'); 
        underInflatedDiv.id = 'underinflated';  
        underInflatedDiv.className = 'col';      

        const underInflatedPercDiv = document.createElement('div');
        underInflatedPercDiv.id = 'underinflatedpercentage';
        underInflatedPercDiv.className = 'metricsummaryperc bg-red';
        const underInflatedPercValueSpan = document.createElement('span');
        //underInflatedPercValueSpan.innerText = unitStatuses.percUnderInflated;        

        const underInflatedPercLabelSpan = document.createElement('span');
        underInflatedPercLabelSpan.innerText = "%"

        underInflatedPercDiv.appendChild(underInflatedPercValueSpan);
        underInflatedPercDiv.appendChild(underInflatedPercLabelSpan);

        const underInflatedLabelTop = document.createElement('div');
        underInflatedLabelTop.className = 'd-flex justify-content-center fw-bolder'
        underInflatedLabelTop.innerText = "Under"; //Correct Pressure
        const underInflatedLabelBottom = document.createElement('div');
        underInflatedLabelBottom.className = 'd-flex justify-content-center fw-bolder'
        underInflatedLabelBottom.innerText = "Inflated";

        underInflatedDiv.appendChild(underInflatedPercDiv);
        underInflatedDiv.appendChild(underInflatedLabelTop);
        underInflatedDiv.appendChild(underInflatedLabelBottom);

        const overInflatedDiv = document.createElement('div'); 
        overInflatedDiv.id = 'overinflated';  
        overInflatedDiv.className = 'col';      

        const overInflatedPercDiv = document.createElement('div');        
        overInflatedPercDiv.id = 'overinflatedpercentage';
        overInflatedPercDiv.className = 'metricsummaryperc bg-purple';
        const overInflatedPercValueSpan = document.createElement('span');
        //overInflatedPercValueSpan.innerText = unitStatuses.percOverInflated;        

        const overInflatedPercLabelSpan = document.createElement('span');
        overInflatedPercLabelSpan.innerText = "%"

        overInflatedPercDiv.appendChild(overInflatedPercValueSpan);
        overInflatedPercDiv.appendChild(overInflatedPercLabelSpan);

        const overInflatedLabelTop = document.createElement('div');
        overInflatedLabelTop.className = 'd-flex justify-content-center fw-bolder'
        overInflatedLabelTop.innerText = "Over"; //Correct Pressure
        const overInflatedLabelBottom = document.createElement('div');
        overInflatedLabelBottom.className = 'd-flex justify-content-center fw-bolder'
        overInflatedLabelBottom.innerText = "Inflated";

        overInflatedDiv.appendChild(overInflatedPercDiv);
        overInflatedDiv.appendChild(overInflatedLabelTop);
        overInflatedDiv.appendChild(overInflatedLabelBottom);

        const noSignalDiv = document.createElement('div'); 
        noSignalDiv.id = 'nosignal';  
        noSignalDiv.className = 'col';      

        const noSignalPercDiv = document.createElement('div');
        noSignalPercDiv.id = 'nosignalpercentage';
        noSignalPercDiv.className = 'metricsummaryperc bg-gray';
        const noSignalPercValueSpan = document.createElement('span');
        //noSignalPercValueSpan.innerText = unitStatuses.percNoSignal;        

        const noSignalPercLabelSpan = document.createElement('span');
        noSignalPercLabelSpan.innerText = "%"

        noSignalPercDiv.appendChild(noSignalPercValueSpan);
        noSignalPercDiv.appendChild(noSignalPercLabelSpan);

        const noSignalLabelTop = document.createElement('div');
        noSignalLabelTop.className = 'd-flex justify-content-center fw-bolder'
        noSignalLabelTop.innerText = "No"; 
        const noSignalLabelBottom = document.createElement('div');
        noSignalLabelBottom.className = 'd-flex justify-content-center fw-bolder'
        noSignalLabelBottom.innerText = "Signal";

        noSignalDiv.appendChild(noSignalPercDiv);
        noSignalDiv.appendChild(noSignalLabelTop);
        noSignalDiv.appendChild(noSignalLabelBottom);

        unitsListHeaderDiv.appendChild(correctPressureDiv);
        unitsListHeaderDiv.appendChild(underInflatedDiv);
        unitsListHeaderDiv.appendChild(overInflatedDiv);
        unitsListHeaderDiv.appendChild(noSignalDiv);

        return unitsListHeaderDiv;

    } catch (e) {
        console.log("unitslistscreenJS - createUnitsListHeader: " + e.error);
        handleError(e, globals.token, "createUnitsListHeader");
    }
}

function createUnitsListFooter() {

    try {

        const unitsListFooterContainerDiv = document.createElement('div');
        unitsListFooterContainerDiv.id = 'unitslistfootercontainer';
        //unitsListFooterContainerDiv.className = 'container-fluid'; //container

        const footerLegendDiv = document.createElement('div');
        footerLegendDiv.id = 'footerlegend';
        const footerLegendSpan = document.createElement('span');
        footerLegendSpan.className='footerlegend text-center text-nowrap';
        footerLegendSpan.innerText = "* Correct Pressure = CP, Under Inflated = UI, Over Inflated = OI";
        footerLegendDiv.appendChild(footerLegendSpan);

        unitsListFooterContainerDiv.appendChild(footerLegendDiv);   

        return unitsListFooterContainerDiv;

    } catch (e) {
        console.log("unitslistscreenJS - createUnitsListFooter: " + e.error);
        handleError(e, globals.token, "createUnitsListFooter");
    }
}

function createUnitContainer(unit) {

    try {

        const unitDiv = document.createElement('div');
        unitDiv.id = `unit${unit.imei}`;

        const unitDivClasses = ['unit'];    
        unitDiv.classList.add(...unitDivClasses);

        if (!isObjectEmpty(globals.selectedUnit) && isObjectEmpty(globals.selectedUnit) != null) {
            if (globals.selectedUnit.imei != null && globals.selectedUnit.imei === unit.imei) {
                unitDiv.classList.add('selectedunit');
                globals.selectedUnit = {};
                localStorage.setItem("vPressureTrack", {});
            }
        }

        let unitDivRow1 = document.createElement('div');
        const unitDivRow1Classes = [ 'row' ];    
        unitDivRow1.classList.add(...unitDivRow1Classes);
        unitDiv.appendChild(unitDivRow1);

        let unitHeaderDiv = document.createElement('div');
        const unitHeaderClasses = ['col-md-3', 'unitheader'];    
        unitHeaderDiv.classList.add(...unitHeaderClasses);
        unitDivRow1.appendChild(unitHeaderDiv);

        let text = '\xa0' + unit.name || unit.imei;
        let t = document.createTextNode(text);
        unitHeaderDiv.appendChild(t);

        let unitStatusDiv = document.createElement('div');
        const unitStatusDivClasses = ['col-md-9', 'unitstatus'];   
        unitStatusDiv.classList.add(...unitStatusDivClasses);
        let unitStatusDiagramDiv = loadUnitStatusDiagram(unit);

        unitStatusDiv.appendChild(unitStatusDiagramDiv);
        unitDivRow1.appendChild(unitStatusDiv);

        return unitDiv;
    } catch (e) {
        console.log("unitgroupjs - createUnitContainer: ERROR " + e);
        handleError(e, globals.token, "createUnitContainer");
    }
}



