
import { SPARE_AXLE } from '../utils/globals.js';

const baseUrl = 'https://cloud-connector-dashboard.restiolabs.com/api';

export async function updateTyreInfo(wheel) {
    try {
        const token = localStorage.getItem('token');

        const requestBody = {
            externalTyreId: wheel.externalTyreId,
            tyreTreadDepth: parseFloat(wheel.tyreTreadDepth)
        };

        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/sensor/${wheel.sensorId}/info`;

        const response = await fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log("Success:", responseData.message);
        } else {
            console.error("Error:", responseData);
        }

    } catch (e) {
        console.error("Error updating tyre info:", e);
    }
}

export async function updateTrailerName(sensor, trailerName) {

    const token = localStorage.getItem('token');

    const repeaterId = sensor.repeater.repeaterId;
    const apiUrl = `${baseUrl}/api/repeater/${repeaterId}/info`;

    const requestBody = {
        trailerName: trailerName
    };

    try {
        const response = await fetch(apiUrl, {
            method: 'PUT', // Assuming it's a PUT request, change to POST if necessary
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        // Return true if the update was successful, else return false
        return response.ok;
    } catch (error) {
        console.error(`Error updating trailerName for repeaterId: ${repeaterId}`, error);
        return false; // Indicate that the update failed
    }
}

export async function getUnitSensorHistoryData(imei, date) {
    try {

        const token = localStorage.getItem('token');
        date = new Date(date);
        //date.setDate(date.getDate() + 1);
        const timestamp = date.toISOString();
        console.log(`timestamp`)
        console.log(timestamp)


        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/sensor-historical?timestamp=${timestamp}`;

        
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;

    } catch (e) {
        console.error('getsensordataJS: getUnitSensorHistory - ERROR ', e);
    }
}
export async function getActionLogData(imei, wheelId) {
    try {

        const token = localStorage.getItem('token');

        const now = new Date();
        const sixMonthsAgo = new Date(now.setMonth(now.getMonth() - 6));
        const timestamp = sixMonthsAgo.toISOString();

        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/wheel/${wheelId}/action?startTimestamp=${timestamp}`;
            

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;

    } catch (e) {
        console.error('getsensordataJS: getActionLogData - ERROR ', e);
    }
}

export async function updateActionLog(imei, wheelId, action) {
    try {
        const token = localStorage.getItem('token');

        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/wheel/${wheelId}/action`;
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ action: action })
        });

        const responseData = await response.json();

        if (response.ok) {
            console.log("Success:", responseData.message);
        } else {
            console.error("Error:", responseData);
        }

    } catch (e) {
        console.error("Error updating action log:", e);
    }
}
export async function getSensorSummaryData(unit, date) {

    const results = await fetchSensorSummaryData(unit.imei, date);

    return await new Promise((resolve, reject) => {

        if (results.data.length) {
            //const data = filterObjectsByTimestamp(results.data);
            const data = results.data;
            let sensorSummaryValues = [];
            for (let s = 0; s < data.length; s++) {
                const sensorSummary = {};
                //sensorSummary.imei = unit.imei;
                //sensorSummary.unitNumber = data[s].wheelId.slice(-1);
                sensorSummary.wheelId = data[s].wheelId;
                //sensorSummary.axle = parseInt(data[s].wheelId.slice(1, 3));
                //sensorSummary.wheelAxle = data[s].wheelId.slice(0, -1);
                sensorSummary.minPressure = data[s].minPressure;
                sensorSummary.maxPressure = data[s].maxPressure;
                //sensorSummary.recommendedPressure = wheel.recommendedPressure;
                sensorSummary.maxTemperature = data[s].maxTemperature;
                sensorSummary.minVoltageValue = data[s].minVoltage;
                sensorSummaryValues.push(sensorSummary);
            }

            if (sensorSummaryValues.length) {
                resolve(sensorSummaryValues);
            } else {
                reject(new Error(`Whoops!`));
            }
        }

    }).catch(alert);
}

export async function getSensorHistoryData(unit, wheel, date) {

    if (typeof variable === 'object' && variable !== null && !Array.isArray(variable)) {
        // variable is an object and not a string or array
    }

    const wheelLocation = typeof wheel === 'object' && wheel !== null ? wheel.wheelId : wheel;
    const results = await fetchSensorHistoryData(unit.imei, wheelLocation, date);

    return await new Promise((resolve, reject) => {

        if (results.data.length) {
            //const data = filterObjectsByTimestamp(results.data);
            const sensors = wheelLocation.slice(1, 3) === SPARE_AXLE.toString() ? unit.spareSensors : unit.wheelSensors;
            const data = results.data;
            let sensorValues = [];
            for (let s = 0; s < data.length; s++) {
                const sensorValue = {};
                sensorValue.id = unit.imei;
                sensorValue.unitNumber = wheelLocation.slice(-1);
                sensorValue.sensorId = data[s].sensorId;
                sensorValue.wheelId = wheelLocation;
                sensorValue.axle = parseInt(wheelLocation.slice(1, 3));
                sensorValue.wheelAxle = wheelLocation.slice(1);
                sensorValue.minPressure = sensors.find(s => s.wheelId === wheelLocation)?.minPressure || wheel.minPressure || 0;
                sensorValue.maxPressure = sensors.find(s => s.wheelId === wheelLocation)?.maxPressure || wheel.maxPressure || 0;
                sensorValue.recommendedPressure = sensors.find(s => s.wheelId === wheelLocation)?.recommendedPressure || wheel.recommendedPressure || 0;
                sensorValue.maxTemperature = sensors.find(s => s.wheelId === wheelLocation)?.maxTemperature || wheel.maxTemperature || 0;
                sensorValue.minVoltageValue = sensors.find(s => s.wheelId === wheelLocation)?.minVoltage || wheel.minVoltage || 0;
                sensorValue.unixTime = new Date(data[s].payloadTS).getTime() / 1000;

                const apiTimestamp = data[s].payloadTS;           
                const entryTime = new Date(apiTimestamp);
                const startTime = new Date(Date.UTC(2024, 6, 4, 7, 0, 0)); // Note: month is zero-based, so July is 6
                const endTime = new Date(Date.UTC(2024, 6, 4, 8, 0, 0));

                // Check if the entry's timestamp is within the range
                //if (entryTime >= startTime && entryTime < endTime) {
                //    console.log(`${entryTime} ${data[s].pressure} bar`);
                //} 

                sensorValue.time = data[s].payloadTS;
                sensorValue.posx = data[s].pos?.x || 0;
                sensorValue.posy = data[s].pos?.y || 0;
                sensorValue.pressure = (parseFloat(data[s].pressure)).toFixed(2);
                sensorValue.temperature = parseInt(data[s].temperature);
                sensorValue.voltage = parseFloat(data[s].voltage);
                sensorValues.push(sensorValue);

            }

            if (sensorValues.length) {
                sensorValues.sort((a, b) => a.unixTime - b.unixTime);     
                resolve(sensorValues);
            } else {
                reject(new Error(`Whoops! getSensorHistoryData`));
            }
        }

    }).catch(alert);
}

export async function fetchWheelSensorData(imei) {
    try {
        const token = localStorage.getItem('token');
        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/sensor`;

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;

    } catch (e) {
        console.error('getsensordataJS: fetchWheelSensorData - ERROR ', e);
    }
}

export async function fetchLocationSensorData(imei) {
    try {
        const token = localStorage.getItem('token');
        const apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/repeater`;

        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;

    } catch (e) {
        console.error('getsensordataJS: fetchLocationSensorData - ERROR ', e);
        return [];
    }
}

async function fetchSensorHistoryData(imei, wheel, date) {

    let apiUrl;      

    // Check if 'date' is a single date object or an array with two date objects
    if (Array.isArray(date) && date.length === 2) {
        apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/wheel/${wheel}/history?datapointInterval=1&startTimestamp=${date[0]}&endTimestamp=${date[1]}`;
    } else if (typeof date === "string") {
        //const dateString = date.toISOString().split('T')[0];
        apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/wheel/${wheel}/history?datapointInterval=1&startTimestamp=${date}`;
    } else {
        throw new Error('Invalid date format');    }

    try {
        const response = await fetch(apiUrl);

        //const response = await fetch(apiUrl, {
        //    method: 'GET',
        //    headers: {
        //        'Authorization': `Bearer ${jwtToken}`,
        //        'Content-Type': 'application/json'
        //    },
        //    credentials: 'include' // Include cookies in the request
        //});

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();

    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
    }
}

async function fetchSensorSummaryData(imei, date) {

    let apiUrl;

    // Check if 'date' is a single date object or an array with two date objects
    if (Array.isArray(date) && date.length === 2) {
        //const startDate = date[0].toISOString().split('T')[0];
        //const endDate = date[1].toISOString().split('T')[0];
        //apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/wheel/1010/history?datapointInterval=16&startTimestamp=${startDate}&endTimestamp=${endDate}`;
        apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/sensor-summary?startTimestamp=${date[0]}&endTimestamp=${date[1]}`;
    } else if (typeof date === "string") {
        //const dateString = date.toISOString().split('T')[0];
        apiUrl = `https://cloud-connector-dashboard.restiolabs.com/api/unit/${imei}/sensor-summary?startTimestamp=${date}`;
    } else {
        throw new Error('Invalid date format');
    }

    try {
        const response = await fetch(apiUrl);
        return await response.json();
    } catch (error) {
        console.error('Error fetching sensor summary data:', error);
        throw error;
    }
}


function getSensorInfo(imei) { // get and show information about selected Sensor

    var sess = wialon.core.Session.getInstance(); // get instance of current Session
    var unit = sess.getItem(imei); // get unit by id
    //var sens = unit.getSensor(103); // get sensor by id

    //get sensor events
    unit.getCurrentSensors(103, null, 20, function (code, data) {
        if (code) { msg(wialon.core.Errors.getErrorText(code)); return; } // exit if error code
        console.log(`API_ERROR ${code} ${JSON.stringify(data)}`);

        let timeTo = session.getServerTime();
        const timeFrom = timeTo - 3600 * 24 * 7;
        unit.getSensorsHistory(1, timeFrom, timeTo, 103, null, function (error, result) {
            if (error) { msg(wialon.core.Errors.getErrorText(code)); return; } // exit if error code
            console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
        });
    });
}



