import { SPARE_AXLE, UNPAIRED_AXLE } from "../utils/globals.js";
export const getUnitStatuses = (units) => {

    const unitStatuses = {};

    let unitTotal = units.length;

    for (let u = 0; u < units.length; u++) {
        //if (units[u].trailers.length) unitTotal = unitTotal + units[u].trailers.length;
    }

    unitStatuses.unitTotal = unitTotal;

    let totalSensorValues = 0;
    let redStatus = 0;
    let purpleStatus = 0;
    let blueStatus = 0;

    for (let unit = 0; unit < units.length; unit++) {
        totalSensorValues = parseInt(totalSensorValues) + parseInt(units[unit].sensors.length);

        if (units[unit].linkedredFlags > 0) {
            redStatus = parseInt(redStatus) + parseInt(units[unit].linkedredFlags);
        }
        if (units[unit].linkedpurpleFlags > 0) {
            purpleStatus = parseInt(purpleStatus) + parseInt(units[unit].linkedpurpleFlags);
        }

        if (units[unit].linkedblueFlags > 0) {
            blueStatus = parseInt(blueStatus) + parseInt(units[unit].linkedblueFlags);
        }

        // if (units[unit].trailers.length > 0) {
        //     for (let t = 0; t < units[unit].trailers.length; t++) {
        //         totalSensorValues = parseInt(totalSensorValues) + parseInt(units[unit].trailers[t].sensors.length);
        //     }
        // }
    }

    unitStatuses.totalSensorValues = totalSensorValues; 
    unitStatuses.greenStatus = parseInt(totalSensorValues) - parseInt((parseInt(redStatus) + parseInt(purpleStatus) + parseInt(blueStatus)));
    unitStatuses.redStatus = redStatus;
    unitStatuses.purpleStatus = purpleStatus;
    unitStatuses.blueStatus = blueStatus;

    unitStatuses.percCorrectPressure = Math.round(unitStatuses.greenStatus / totalSensorValues * 100);
    unitStatuses.percUnderInflated = Math.round(unitStatuses.redStatus / totalSensorValues * 100);
    unitStatuses.percOverInflated = Math.round(unitStatuses.purpleStatus / totalSensorValues * 100);
    unitStatuses.percNoSignal = Math.round(unitStatuses.blueStatus / totalSensorValues * 100);

    return unitStatuses;

}

export const getWheelProperties = (sensors) => {

    try { 
        if (sensors.length > 0) {
            for (let s = 0; s < sensors.length; s++) {
                sensors[s].wheelType = "wheelactive";
                sensors[s].sensorType = "";
                const { wheelId } = sensors[s]; // Destructure for cleaner access
                const axle = parseInt(wheelId.slice(1,3));

                if (axle !== SPARE_AXLE && axle !== UNPAIRED_AXLE) {
                    // Handle non-spare and non-unpaired axles
                    sensors[s].tyreName = `A${wheelId.slice(1, 3).replace(/^0+/, "")}-T${wheelId.slice(0, 1)}`;
                } else {
                    // Handle spare and unpaired axles
                    const prefix = axle === SPARE_AXLE ? 'S' : 'U';
                    sensors[s].tyreName = `${prefix}-${wheelId.slice(0, 1)}`;
                }
                sensors[s].pressureLabelColour = "green";
                sensors[s].temperatureLabelColour = "green";
                sensors[s].voltageLabelColour = "green";
                sensors[s].noSignalLabelColour = "green";

                if (sensors[s].currentPressure == null && sensors[s].currentTemperature == null && sensors[s].currentVoltage == null) {
                    sensors[s].sensorType = "tyrenosignal";
                    sensors[s].noSignalLabelColour = "blue";
                }

                if (!sensors[s].signal) {
                    sensors[s].sensorType = "tyrenosignal";
                    sensors[s].noSignalLabelColour = "blue";
                }

                if (parseFloat(sensors[s].currentPressure) > parseFloat(sensors[s].maxPressure)) {
                    sensors[s].sensorType = "tyrepurple";
                    sensors[s].pressureLabelColour = "purple";
                }

                if (parseFloat(sensors[s].currentPressure) < parseFloat(sensors[s].minPressure)) {
                    sensors[s].sensorType = "tyrered";
                    if (parseInt(sensors[s].wheelId.slice(1, 3)) !== SPARE_AXLE && (parseFloat(sensors[s].currentPressure) < parseFloat(sensors[s].recommendedPressure) * 20/100)) {
                        sensors[s].sensorType = "wheelblink"; 
                    }
                    sensors[s].pressureLabelColour = "red";
                }

                if (parseInt(sensors[s].currentTemperature) > parseInt(sensors[s].maxTemperature)) {
                    if (sensors[s].sensorType !== "tyrered") {
                        sensors[s].sensorType = "tyreorange";
                    }
                    sensors[s].temperatureLabelColour = "orange";
                }

                if (parseFloat(sensors[s].currentVoltage) < parseFloat(sensors[s].minVoltage)) {
                    if (sensors[s].sensorType !== "tyrepurple" && sensors[s].sensorType !== "tyrered" && sensors[s].sensorType !== "tyreorange") {
                        sensors[s].sensorType = "tyreyellow";
                    }
                    sensors[s].voltageLabelColour = "yellow";
                }
            }
        }

        return sensors;

    } catch (e) {        
        console.error('unitsJS: getWheelProperties - ERROR: ', e);
    }
}

export const getTyreArrayIndex = (tyre, axleSensors) => {

    let tyreArrayIndex = 0;
    for (let s = 0; s < axleSensors.length; s++) {
        if (parseInt(axleSensors[s].sensorName.slice(1, 2)) === tyre) {
            tyreArrayIndex = s;
            break;
        }
    }
    return tyreArrayIndex;
}