export const getAddressFromCoordinates = async(latitude, longitude) =>  {
    try {
        const address = await reverseGeocodeNominatim(formatCoordinate(latitude), formatCoordinate(longitude));
        return address;
    } catch (error) {
        console.error('Error getting address:', error);
        return 'Address not available';
    }
}

export const formatCoordinate = (coordinate) => {
    // Ensure the coordinate is a float and divide by 1e7 if it's in an integer format like -339791899
    const formattedCoordinate = coordinate > 100000000 || coordinate < -100000000 ? coordinate / 1e7 : coordinate;
    return parseFloat(formattedCoordinate);
}

export const reverseGeocodeNominatim = async(latitude, longitude) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;

    try {
        const response = await fetch(url);
        const data = await response.json();
        const addressData = data.display_name;
        const parts = addressData.split(',');

        const address = parts[0].trim() + " " + parts[1].trim() + ", " + parts[parts.length - 1].trim() + ", " + parts[parts.length - 2].trim();

        return address;
    } catch (error) {
        console.error('Error:', error);
        return 'Address not available';
    }
}