import { sendLoginMail } from "../data/auth.js";

export const handleLogin = (customerToken) => {
    const loginForm = document.getElementById('login');
    if (loginForm) loginForm.style.display = 'none';
    const userName = customerToken?.decodedToken?.user?.email
    const version = getVersion(); //toppane
    showSplash(userName, version);  
  }
  
function getVersion() {

    const currentVersion = "2.0.0"; 
    const previousVersion = localStorage.getItem("version");

    if (previousVersion) {
        if (`PressureTrack v${currentVersion}` !== previousVersion) {
            localStorage.setItem("version", `PressureTrack v${currentVersion}`);
            location.reload(true);
        }
    } else {
        localStorage.setItem("version", `PressureTrack v${currentVersion}`);
            location.reload(true);
    }

    //document.getElementById('version').innerText = `version ${currentVersion}`;

    return currentVersion;
}

const showSplash = (version, userName) => {

    document.getElementById('header').style.display = 'none';
    document.getElementById('footer').style.display = 'none';
  
    const splashContainer = document.getElementById('splashcontainer');
    splashContainer.className = 'container-splash';
    while (splashContainer.hasChildNodes()) {
        splashContainer.removeChild(splashContainer.lastChild);
    }
    const splashFragment = document.createDocumentFragment();
  
    const splash = document.createElement('div');
    splash.id = 'splash';
  
    const splashIcon = document.createElement('div');
    splashIcon.id = 'splashicon';
    splashIcon.className = 'splashicon';
    splash.appendChild(splashIcon);
  
    const splashLogo = document.createElement('div');
    splashLogo.id = 'splashlogo';
    splashLogo.className = 'splashlogo';
    splash.appendChild(splashLogo);
  
    const splashLabel = document.createElement('div');
    splashLabel.id = 'splashlabel';
    splashLabel.className = 'splashlabel';
    splashLabel.innerText = `If you can't measure it, you can't manage it.`;
    splash.appendChild(splashLabel);
  
    const userNameSplash = document.createElement('div');
    userNameSplash.id = 'username';
    userNameSplash.className = 'username';
    userNameSplash.innerText = userName;
    splash.appendChild(userNameSplash);
  
    const versionSplash = document.createElement('div');
    versionSplash.id = 'splashversion';
    versionSplash.className = 'version';
    versionSplash.innerText = `version ${version}`;
    splash.appendChild(versionSplash);
  
    splashFragment.appendChild(splash);
    splashContainer.appendChild(splashFragment);
}