import '../styles/app.scss';

import globals from '../../common/utils/globals.js';
import { SPARE_AXLE } from '../../common/utils/globals.js';
import { Wheel } from '../../common/components/wheel.js';

import { handleError } from './components/errorhandler.js';

import { addLeadingZero } from '../../common/utils/general.js';

import { waitCursor } from '../../common/components/waitcursor.js'

import {formatDateTime, convertUnixTime} from '../../common/utils/timeformats.js';
import {getTyreArrayIndex } from '../../common/data/handlesensordata.js'
import {
    createUnitElement,
    createAxleElement,
    getAxleActiveStatus,
    createTyreNameElement, 
    createSensorIdElement, 
    createSensorPressureElement, 
    createSensorTemperatureElement, 
    createSensorVoltageElement, 
    createSensorNoSignalElement } from '../../common/components/unitdiagrams.js';

    import { showUnitMapScreen } from './unitmapscreen.js';

import { showWheelReport } from './wheelreports.js'; 

const wheels = [];

export const showDiagramScreen = (unit) => {    
    
    createDiagram(unit);
    createPageFooter(unit);
}

const createDiagram = (unit) => {  

    const diagram = document.getElementById('maincontainer');
    //wheelsDiagramScreen.scrollIntoView();
    diagram.className = 'maincontainer';
    while (diagram.hasChildNodes()) {
        diagram.removeChild(diagram.lastChild);
    }
    const diagramFragment = document.createDocumentFragment();  
    const diagramDiv = getDiagram(unit);
    diagramFragment.appendChild(diagramDiv);  
    diagram.appendChild(diagramFragment);     
}

const getDiagram = (unit) => {

    const diagramDiv = document.createElement('div');
    diagramDiv.id = 'diagram';

    const unitConfig = unit.unitConfig;
    for (let un = 0; un < unitConfig.length; un++) {

        const unitDiv = createUnitElement('w', un, unit);
        diagramDiv.appendChild(unitDiv);

        const axles = unitConfig[un].axles;
        for (let a = 0; a < axles.length; a++) {
                const axleDiv = createAxleElements(unit, un, a + +1);
                //createReportsAxleElements(unit, unitNumber, axle)
                unitDiv.appendChild(axleDiv);           
        }
    }

    // if (unit.spareSensors.length) {
    //     const unitSpareDiv = createUnitElement(SPARE_AXLE, unit);
    //     diagramDiv.appendChild(unitSpareDiv);
    //         const spareAxleDiv = createSpareAxleElements( unit);
    //         unitSpareDiv.appendChild(spareAxleDiv);

    // }

    return diagramDiv;
}

const createAxleElements = (unit, unitNumber, axle) => {

    const axleDiv = document.createElement('div');
    //axleDiv.className = 'diagramaxle';
    axleDiv.className = 'wheelaxle';

    const isAxleActive = getAxleActiveStatus(unit.axleRanges, `${addLeadingZero(axle)}${unitNumber}`);
    const axleSensors = unit.wheelSensors.filter(s => 
        parseInt(s.wheelId.slice(1, 3)) === axle && parseInt(s.wheelId.slice(3)) === unitNumber
    );

    // Create a Map for axleSensorData keyed by wheelId for easy lookup
    const wheelDataMap = new Map(axleSensors.map(sensor => [sensor.wheelId, sensor]));

    const wheelConfig = unit.unitConfig[unitNumber].axles[axle - 1].wheelConfig;

    // Add axle status and configuration-specific classes
    const axleStatusClass = isAxleActive ? 'axleactive' : 'axleinactive';
    const axledivclasses = ['shortaxle', axleStatusClass];
    
    // Helper function to create and render a Wheel instance
    const createAndRenderWheel = (wheelId, container) => {
        const wheelSensorData = wheelDataMap.get(wheelId) || { wheelId}; // Use empty object if no data

        // Create a Wheel instance with available sensor data
        const wheel = new Wheel(wheelSensorData);
        wheels.push(wheel);

        // Optional: Set thresholds for alerts
        wheel.setThresholds({ minPressure: wheel.minPressure, maxTemperature: wheel.maxTemperature });

        // Render the Wheel instance into the container               
        container.appendChild(wheel.renderWheel());  
    };

    if (wheelConfig === 0) {
        createAndRenderWheel(`1${addLeadingZero(axle)}${unitNumber}`, axleDiv);
        const axleElement = createAxleElement();
        axleDiv.appendChild(axleElement);
        createAndRenderWheel(`4${addLeadingZero(axle)}${unitNumber}`, axleDiv);
    } else {
        for (let s = 1; s <= 2; s++) {
            createAndRenderWheel(`${s}${addLeadingZero(axle)}${unitNumber}`, axleDiv);
        }
        const axleElement = createAxleElement(unit, axleSensors, axledivclasses);
        axleDiv.appendChild(axleElement);
        for (let s = 3; s <= 4; s++) {
            createAndRenderWheel(`${s}${addLeadingZero(axle)}${unitNumber}`, axleDiv);
        }
    }

    // Add axle information if it exists
    if (axleSensors.length) {
        const axleInfo = unit.axleRanges.find(ar => ar.axleNumber === axleSensors[0].wheelId.slice(1));
        //* if (axleInfo) {
        //     const axleInfoDiv = createAxleInfoElement(axleInfo, axleSensors[0].wheelId.slice(1));
        //     axleDiv.appendChild(axleInfoDiv);
        //* }
    }     

    return axleDiv;
};

const createPageFooter = (unit) => {

    createDiagramToggle();
    createFooterbuttons(unit);    
}

const createDiagramToggle = () => {

    const diagramToggle = document.getElementById('diagramtoggle');   
    diagramToggle.className = 'footernav row p-2 border-bottom border-pt-border'; 
    while (diagramToggle.hasChildNodes()) {
        diagramToggle.removeChild(diagramToggle.lastChild);
    }   
    const showSensorValuesLabel = document.createElement('div');   
    showSensorValuesLabel.className = 'col-8 my-auto showsensorvalueslabel';
    showSensorValuesLabel.innerText ='Show Sensor Values';
    diagramToggle.appendChild(showSensorValuesLabel);

    const toggleDiv = document.createElement('div');  
    toggleDiv.className = 'col form-check form-switch form-switch-md p-2 me-2';
    const toggleInput = document.createElement('input');   
    toggleInput.className = 'form-check-input float-end';  
    toggleInput.id='toggle'; 
    toggleInput.type='checkbox';
    toggleInput.role ='switch';
    //toggleInput.checked=true;

    const toggleLabel = document.createElement('label');  
    toggleLabel.className = 'form-check-label';  
    toggleLabel.for='toggle'

    // showSensorValuesText.id = 'showsensorvaluestext';
    // showSensorValuesText.innerText = 'Show Sensor Values';

    toggleDiv.appendChild(toggleInput);
    toggleDiv.appendChild(toggleLabel);

    diagramToggle.appendChild(toggleDiv);

    diagramToggle.onclick = () => {

        const newMode = toggleInput.checked ? 'SENSORVALUESDIAGRAM' : 'WHEELSDIAGRAM';
        globals.currentView = newMode;
        localStorage.setItem("vPressureTrack", newMode);
        wheels.forEach(wheel => {
            wheel.setViewMode(newMode);
        });
    };
}

const createFooterbuttons = (unit) => {

    const footerButtons = document.getElementById('footerbuttons');    
    while (footerButtons.hasChildNodes()) {
        footerButtons.removeChild(footerButtons.lastChild);
    }
    footerButtons.className = 'footernav row p-2 text-center';

    const unitButton = document.createElement('div');   
    unitButton.className = 'col border-end border-pt-border';

    const tyreSVG = document.createElement('svg');   
    tyreSVG.className = 'imgbutton buttontyre-enabled'; //class="button imgbutton buttontyre-disabled"

    const unitButtonText = document.createElement('span');   
    unitButtonText.className = 'buttontext'; //class="buttontext buttontext-disabled"
    unitButtonText.innerText='Unit' //'\xa0Unit'

    unitButton.appendChild(tyreSVG);
    unitButton.appendChild(unitButtonText);

    const mapButton = document.createElement('div');   
    mapButton.className = 'col border-end border-pt-border';

    const pinSVG = document.createElement('svg');   
    pinSVG.className = 'imgbutton buttonpin-disabled'; //class="button imgbutton buttontyre-disabled"

    const mapButtonText = document.createElement('span');   
    mapButtonText.className = 'buttontext buttontext-disabled'; //class="buttontext buttontext-disabled"
    mapButtonText.innerText='Map' //'\xa0Unit'

    mapButton.appendChild(pinSVG);
    mapButton.appendChild(mapButtonText);

    footerButtons.appendChild(unitButton);
    footerButtons.appendChild(mapButton);

    unitButton.onclick = (e) => {
        if(globals.wheelReport) { 
            globals.wheelReport = false;
        }    
         
        tyreSVG.className = 'imgbutton buttontyre-enabled'; 
        pinSVG.className = 'imgbutton buttonpin-disabled'; 
        // globals.currentView='WHEELSDIAGRAM';
        // localStorage.setItem("vPressureTrack", 'WHEELSDIAGRAM');
        // showDiagramScreen(unit);
        const toggleInput = document.getElementById('toggle'); 
        toggleInput.disabled = false;
        if (toggleInput.checked===true){
            if(globals.currentView != 'SENSORVALUESDIAGRAM' ) { 
                globals.currentView = 'SENSORVALUESDIAGRAM';
            }   
            localStorage.setItem("vPressureTrack", 'SENSORVALUESDIAGRAM');
            // showSensorValuesDiagramScreen(units, unit);
            showDiagramScreen(unit); 
        } else {
            if(globals.currentView != 'WHEELSDIAGRAM' ) { 
                globals.currentView = 'WHEELSDIAGRAM';
            }   
            localStorage.setItem("vPressureTrack", 'WHEELSDIAGRAM');
            showDiagramScreen(unit); 
        }
    };

    mapButton.onclick = (e) => {
        if(globals.wheelReport) { 
            globals.wheelReport = false;
        } 
        const toggleInput = document.getElementById('toggle'); 
        // toggleInput.disabled = true;
        pinSVG.className = 'imgbutton buttonpin-enabled';  
        tyreSVG.className = 'imgbutton buttontyre-disabled'; 
        globals.currentView='UNITMAP';
        localStorage.setItem("vPressureTrack", 'UNITMAP');
        showUnitMapScreen(unit);
    };
}

export const createSpareAxleElements = (diagramView, unit) => {

    const spareAxleDiv = document.createElement('div');
    spareAxleDiv.className = 'diagramaxle';

    const axleSensors = unit.spareSensors;

    for (let s = 0; s < axleSensors.length; s++) {
        const tyreDiv = createSpareTyreElement(diagramView, unit, parseInt(axleSensors[s].wheelId.slice(0, 1)), axleSensors);
        spareAxleDiv.appendChild(tyreDiv);
    }

    return spareAxleDiv;
}

export const createSpareTyreElement = (diagramView, unit, tyre, axleSensors) => {

    const wheel = document.createElement('div');

    const tyreNameDiv = createTyreNameElement(SPARE_AXLE, tyre);

    let tyreDiv = document.createElement('div');
    if (axleSensors.length > 0) {

        let tyredivclasses = ['wheeldefault'];
        let sensorType = 'tyrecorrect';

        if (!axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0]?.signal) {
            sensorType = 'tyrenosignal';
        } else {
            if (axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0]?.hasOwnProperty('sensorType')
                && axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0]?.sensorType !== "") {
                sensorType = axleSensors.filter(as => parseInt(as.wheelId.slice(0, 1)) === tyre)[0]?.sensorType || 'tyrecorrect';
            }
        }

        if (diagramView === "SENSORVALUESDIAGRAM") {

            wheel.className = 'sensorvaluesinfo';

            tyredivclasses = ['sensorvaluesdefault'];

            tyreNameDiv.className = 'sensorvaluestyrename';

            tyreDiv = createSensorValuesDiagramSpareOptions(tyreDiv, axleSensors, tyre);

        } else {
            //tyreDiv.id = 'wheel' + tyre + axleSensors[0].wheelId.slice(1);
            const axleSensor = axleSensors.find(s => s.wheelId === tyre + axleSensors[0].wheelId.slice(1));
            tyreDiv.id = `wheel${axleSensor.sensorId}`;
            tyredivclasses = ['wheeldefault', sensorType];
        }

        tyreDiv.classList.add(...tyredivclasses);
        // Check if the sensor has no signal
        //if (!axleSensors[tyreArrayIndex].signal) {
        //    tyreDiv.style.backgroundColor = '#8b959d';  // Change only the background color
        //} 

        wheel.appendChild(tyreNameDiv);
        wheel.appendChild(tyreDiv);
    }      

    return wheel;
}

function createSpareTyreAxleElements(unit, axleSensors) {

    let tyreSpareAxleDiv = document.createElement('div');
    tyreSpareAxleDiv.className = 'wheelaxle';

    if (axleSensors.length > 0) {

        for (let st = 0; st < axleSensors.length; st++) {
            console.log(axleSensors[st].sensorName);
            let spare = parseInt(axleSensors[st].sensorName.slice(1, 2));
            let tyreDiv = createTyreElement(unit, 9, spare, axleSensors); 
            console.log('tyrediv')
            tyreSpareAxleDiv.appendChild(tyreDiv);
        }
    } 
    return tyreSpareAxleDiv;
}

function createTyreIdElement(unit, axle, tyre, axleSensors) {

    const tyreIdDiv = document.createElement('div');
    tyreIdDiv.className = 'tyreid';
    if (parseInt(axle) === 9) tyreIdDiv.innerText = `S-${tyre}`;
    else tyreIdDiv.innerText = `A${axle}-T${tyre}`;
    // let tyreArrayIndex = 0;  
    // if (axleSensors.length > 0){
    //     tyreArrayIndex = getTyreArrayIndex(tyre, axleSensors); 
    //     tyreIdDiv.id = 'wheel' + axleSensors[0].sensorName.slice(0, 1) + tyre;

    //     let tyredivclasses = ['wheeldefault'];  

    //     let sensorType = 'tyrecorrect';
    //     if (axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].hasOwnProperty('sensorType')
    //         && axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].sensorType !== "") {
    //         sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].sensorType;
    //     }


    //         tyredivclasses = ['wheel', sensorType];  

          
    //         tyreIdDiv.classList.add(...tyredivclasses);
    // }


    return tyreIdDiv;
}

function createTyreElement(unit, axle, tyre, axleSensors) {

    const wheel = document.createElement('div');

    const tyreIdDiv = createTyreIdElement(unit, axle, tyre, axleSensors);

    let tyreDiv = document.createElement('div');
    let tyreArrayIndex = 0;  
    if (axleSensors.length > 0){
        tyreArrayIndex = getTyreArrayIndex(tyre, axleSensors); 
        tyreDiv.id = 'wheel' + axleSensors[0].sensorName.slice(0, 1) + tyre;

        let tyredivclasses = ['wheeldefault'];  

        let sensorType = 'tyrecorrect';
        if (axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].hasOwnProperty('sensorType')
            && axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].sensorType !== "") {
            sensorType = axleSensors.filter(as => parseInt(as.sensorName.slice(1, 2)) === tyre)[0].sensorType;
        }

        if (globals.currentView === "SENSORVALUESDIAGRAM"){

            let sensorPressureDiv = {};
            let sensorTemperatureDiv = {};
            let sensorVoltageDiv = {};                      
        
            if (sensorType !== "tyrenosignal") {
                sensorPressureDiv = createSensorPressureElement(tyreArrayIndex, axleSensors);
                sensorTemperatureDiv = createSensorTemperatureElement(tyreArrayIndex, axleSensors);
                sensorVoltageDiv = createSensorVoltageElement(tyreArrayIndex, axleSensors);

                tyreDiv.appendChild(sensorPressureDiv);
                tyreDiv.appendChild(sensorTemperatureDiv);
                tyreDiv.appendChild(sensorVoltageDiv);

            } else {
                tyredivclasses = ['wheelnosignal'];
                tyreDiv.innerText= 'No Signal';
            }
        } else {
            tyredivclasses = ['wheel', sensorType];  
        }
          
        tyreDiv.classList.add(...tyredivclasses);
    }

    ////
    tyreDiv.onclick = async function () {
        waitCursor('appstatus');   

        const sensorValues = await loadSensorValuesMessages(unit, axleSensors[tyreArrayIndex]);    

        if (sensorValues == null) {
            let diagramWaitCursor = document.getElementById('diagramwaitcursor');
            while (diagramWaitCursor.firstChild) {
                diagramWaitCursor.removeChild(diagramWaitCursor.firstChild);
            }
        }
        else {
            showWheelReport(unit, axle, tyre, sensorValues); 
            // let trailerUnit = unit;
            // if(unit.trailerId == null)
            //     showWheelReport(unit, axle, tyre, sensorValues); 
            // else{

            //     const unitLinkedToId = trailerUnit.unitLinkedToId
            //     unit = {};

            //     for (let u = 0; u < units.length; u++) {
            //         if (units[u].imei === unitLinkedToId){
            //         unit = units[u];
            //         break;
            //         }
            //     }

            //     showWheelReport(unit, axle, tyre, sensorValues); 
            // }
        }
    };

    ///


    wheel.appendChild(tyreIdDiv);
    wheel.appendChild(tyreDiv);

    return wheel;
}

export function createAxleInfoElement(axleRange, axleNoUnitNo) {
    
    const axleInfoDiv = document.createElement('div');      
    const axleinfodivclasses = ['axleinfo', 'axleinfohide'];
    axleInfoDiv.classList.add(...axleinfodivclasses);

    const mrpDiv = createManufacturersRecommendedPressureElement(axleNoUnitNo, axleRange?.recommendedPressure || null);
    const pdaLowDiv = createPressureDeviationAllowedLowElement(axleNoUnitNo, axleRange?.pressureDeviationAllowedLow || null);
    const minPressureDiv = createMinPressureValueElement(axleNoUnitNo, axleRange?.minPressure || null);
    const pdaHighDiv = createPressureDeviationAllowedHighElement(axleNoUnitNo, axleRange?.pressureDeviationAllowedHigh || null);
    const maxPressureDiv = createMaxPressureValueElement(axleNoUnitNo, axleRange?.maxPressure || null);
    const maxTempDiv = createMaxTemperatureValueElement(axleNoUnitNo, axleRange?.maxTemperature || null);
    const minVoltageDiv = createMinVoltageValueElement(axleNoUnitNo, axleRange?.minVoltage || null);

    axleInfoDiv.appendChild(mrpDiv);    
    axleInfoDiv.appendChild(pdaLowDiv);    
    axleInfoDiv.appendChild(minPressureDiv);
    axleInfoDiv.appendChild(pdaHighDiv);
    axleInfoDiv.appendChild(maxPressureDiv);
    axleInfoDiv.appendChild(maxTempDiv);
    axleInfoDiv.appendChild(minVoltageDiv);

    return axleInfoDiv;
}

function createManufacturersRecommendedPressureElement(mrpValue) {

    if (mrpValue == null || mrpValue == "null" || mrpValue == "") mrpValue = "--";

    let mrpDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Rec Pressure (bar): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    let valueSpanText = document.createTextNode(mrpValue);
    valueSpan.className = 'boldtextmaxminvalue';
    valueSpan.appendChild(valueSpanText);

    let mrpSpan = document.createElement('span');
    mrpSpan.appendChild(labelSpan);
    mrpSpan.appendChild(valueSpan);

    mrpDiv.appendChild(mrpSpan);

    return mrpDiv;
}

function createPressureDeviationAllowedHighElement(axleNoUnitNo, pdaValue) {

    if (pdaValue == null || pdaValue == "null" || pdaValue == "") pdaValue = "--";

    let pdaHighDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Deviation - Max (%): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue';
    valueSpan.id = `a${axleNoUnitNo}highppd`; // Assign an id here
    valueSpan.textContent = pdaValue; // Use textContent here

    let pdaSpan = document.createElement('span');
    pdaSpan.appendChild(labelSpan);
    pdaSpan.appendChild(valueSpan);

    pdaHighDiv.appendChild(pdaSpan);

    return pdaHighDiv;
}

function createPressureDeviationAllowedLowElement(axleNoUnitNo, pdaValue) {

    if (pdaValue == null || pdaValue == "null" || pdaValue == "") pdaValue = "--";

    let pdaLowDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Deviation - Min (%): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    valueSpan.className = 'boldtextmaxminvalue';
    valueSpan.id = `a${axleNoUnitNo}lowppd`; // Assign an id here
    valueSpan.textContent = pdaValue; // Use textContent here

    let pdaSpan = document.createElement('span');
    pdaSpan.appendChild(labelSpan);
    pdaSpan.appendChild(valueSpan);

    pdaLowDiv.appendChild(pdaSpan);

    return pdaLowDiv;
}

function createMinPressureValueElement(minPressurevalue) {

    if (minPressurevalue == null || minPressurevalue == "null" || minPressurevalue == "") minPressurevalue = "--";

    let minPressureDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Min Pressure (bar): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    let valueSpanText = document.createTextNode(minPressurevalue);
    valueSpan.className = 'boldtextmaxminvalue pressurered';
    valueSpan.appendChild(valueSpanText);

    let minPressureSpan = document.createElement('span');
    minPressureSpan.appendChild(labelSpan);
    minPressureSpan.appendChild(valueSpan);

    minPressureDiv.appendChild(minPressureSpan);

    return minPressureDiv;
}

function createMaxPressureValueElement(maxPressureValue) {

    if (maxPressureValue == null || maxPressureValue == "null" || maxPressureValue == "") maxPressureValue = "--";

    let maxPressureDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Max Pressure (bar): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    let valueSpanText = document.createTextNode(maxPressureValue); 
    valueSpan.className = 'boldtextmaxminvalue pressurepurple';
    valueSpan.appendChild(valueSpanText);

    let maxPressureSpan = document.createElement('span');
    maxPressureSpan.appendChild(labelSpan);
    maxPressureSpan.appendChild(valueSpan);

    maxPressureDiv.appendChild(maxPressureSpan);

    return maxPressureDiv;
}

function createMaxTemperatureValueElement(maxTemperatureValue) {

    if (maxTemperatureValue == null || maxTemperatureValue == "null" || maxTemperatureValue == "") maxTemperatureValue = "--";

    let maxTemperatureDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Max Temp (°C): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    let valueSpanText = document.createTextNode(maxTemperatureValue);
    valueSpan.className = 'boldtextmaxminvalue temperatureorange';
    valueSpan.appendChild(valueSpanText);

    let maxTemperatureSpan = document.createElement('span');
    maxTemperatureSpan.appendChild(labelSpan);
    maxTemperatureSpan.appendChild(valueSpan);

    maxTemperatureDiv.appendChild(maxTemperatureSpan);

    return maxTemperatureDiv;
}

function createMinVoltageValueElement(minVoltageValue) {

    if (minVoltageValue == null || minVoltageValue == "null" || minVoltageValue == "") minVoltageValue = "--";

    let minVoltageDiv = document.createElement('div');

    let labelSpan = document.createElement('span');
    let labelSpanText = document.createTextNode("Min Voltage (V): ");
    labelSpan.appendChild(labelSpanText);

    let valueSpan = document.createElement('span');
    let valueSpanText = document.createTextNode(minVoltageValue);
    valueSpan.className = 'boldtextmaxminvalue voltageyellow';
    valueSpan.appendChild(valueSpanText);

    let minVoltageSpan = document.createElement('span');
    minVoltageSpan.appendChild(labelSpan);
    minVoltageSpan.appendChild(valueSpan);

    minVoltageDiv.appendChild(minVoltageSpan);

    return minVoltageDiv;
}

function calculateMinMaxPressure(textbox) {

    let manufacturersRecommendedPressure = parseFloat(document.getElementById('manufacturersRecommendedPressure').value);
    let percentageDeviationAllowed = parseFloat(document.getElementById('percentageDeviationAllowed').value);
    if (manufacturersRecommendedPressure && percentageDeviationAllowed) {
        document.getElementById("minPressure").value = calculateMinPressure(parseFloat(manufacturersRecommendedPressure), parseFloat(percentageDeviationAllowed));
        document.getElementById("maxPressure").value = calculateMaxPressure(parseFloat(manufacturersRecommendedPressure), parseFloat(percentageDeviationAllowed));
    }

    return true;
}

