import '../styles/app.scss';
import { formatCoordinate } from '../../common/utils/coordinateformats.js';
import { initialiseMap, showUnitOnMap } from '../../common/components/unitmap.js';

export const showUnitMapScreen = async (unit) => {
    
    const unitMapScreen = document.getElementById('maincontainer');
    while (unitMapScreen.hasChildNodes()) {
        unitMapScreen.removeChild(unitMapScreen.lastChild);
    }

    const unitMapFragment = document.createDocumentFragment();

    const unitMapMainDiv = document.createElement('div');
    unitMapMainDiv.id = 'unitmap';
    unitMapMainDiv.className = 'unitmap';

    unitMapFragment.appendChild(unitMapMainDiv);
    unitMapScreen.appendChild(unitMapFragment);

    initialiseMap('unitmap');
    const location = {
        longitude: formatCoordinate(unit.location.longitude),
        latitude: formatCoordinate(unit.location.latitude)
    };
    showUnitOnMap(location);
}





