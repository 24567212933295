import globals, { SPARE_AXLE } from "../utils/globals.js";
import { addLeadingZero } from "../utils/general.js";
import { Wheel } from "./wheel.js";

export const createTyreNameElement = (axle, tyre) => {

    let sensorNameDiv = document.createElement('div');
    sensorNameDiv.className = "axletyresensor";
    if (axle === "S")
        sensorNameDiv.innerHTML = "Spare " + tyre;
    else
        sensorNameDiv.innerHTML = "A" + axle + "-T" + tyre;

    return sensorNameDiv;
}

export const createUnitElement = (diagram, unitNumber, unit) => {

    const unitDiv = document.createElement('div');
    unitDiv.className = 'unitbox';

    if (unitNumber < unit.unitConfig.length) {
        if (unitNumber !== 0) {
            const unitHeader = createTrailerHeader(diagram, unit.wheelSensors.filter(s => s.wheelId.slice(-1) === unitNumber.toString()), unit.wheelSensors.find(s => s.wheelId.slice(-1) === unitNumber.toString()).repeater.trailerName || unitNumber);
            unitDiv.appendChild(unitHeader);
        }
    } else {
        if (unitNumber === SPARE_AXLE) {
            const unitHeader = createSpareTyresHeader();
            unitDiv.appendChild(unitHeader);
        } else {
            const unitHeader = createUnpairedSensorsHeader();
            unitDiv.appendChild(unitHeader);
        }
    }
    
    return unitDiv;
}

function createUnitNameElements(unitName) {

    let unitNameHeader = document.createElement('h6');
    let unitNameHeaderNode = document.createTextNode(unitName);
    unitNameHeader.appendChild(unitNameHeaderNode);
   
    return unitNameHeader;
}




export const createAxleElement = () => {

    const axleDiv = document.createElement('div');
    const axleSvg = document.createElement('svg');   
    axleSvg.className = 'shortaxle'; 

    axleDiv.appendChild(axleSvg);

    // const axleArmLeftDiv = document.createElement('div');
    // axleArmLeftDiv.classList.add(...axledivclasses);
    // const axleDougnutDiv = document.createElement('div');
    // axleDougnutDiv.className = 'doughnut';
    // const axleArmRightDiv = document.createElement('div');
    // axleArmRightDiv.classList.add(...axledivclasses);
    // axleDiv.appendChild(axleArmLeftDiv);
    // axleDiv.appendChild(axleDougnutDiv);
    // axleDiv.appendChild(axleArmRightDiv);

    return axleDiv;
}

export const getAxleActiveStatus = (axleRanges, axleNoUnitNo) => {

        if (axleRanges) {
            const axle = axleRanges.find(axle => axle.axleNumber === axleNoUnitNo);
            if (!axle) {
                return false;
            }
    
            return (
                axle.recommendedPressure > 0 ||
                axle.pressureDeviationAllowedLow > 0 ||
                axle.pressureDeviationAllowedHigh > 0 ||
                axle.minPressure > 0 ||
                axle.maxPressure > 0 ||
                axle.maxTemperature > 0 ||
                axle.minVoltage > 0
            );
        } else { return false; }
    }


export const createTyreIdElement = (tyreArrayIndex, axleSensors) => {

    let tyreId = document.createElement('div');
    tyreId.className = 'tyreid';
    tyreId.innerText = axleSensors[tyreArrayIndex].tyreId;

    return tyreId;
}

export const createSensorIdElement = (sensorId) => {

    let sensorIdDiv = document.createElement('div'); 
    sensorIdDiv.className = 'sensorid';
    sensorIdDiv.innerHTML = sensorId;

    return sensorIdDiv;
}

export const createSensorPressureElement = (tyreArrayIndex, axleSensors) => {
    
    console.log(`${axleSensors[tyreArrayIndex]}`)
    let sensorPressureDiv = document.createElement('div');
    let sensorvaluesdivclasses = ['sensorvalues'];
    
    if (axleSensors[tyreArrayIndex].pressureLabelColour != 'green')
        sensorvaluesdivclasses = ['sensorvalues', `bg-${axleSensors[tyreArrayIndex].pressureLabelColour}`];


    sensorPressureDiv.classList.add(...sensorvaluesdivclasses);

    let pressure = axleSensors[tyreArrayIndex].currentPressure; 
    sensorPressureDiv.innerHTML = pressure + " bar";    
    return sensorPressureDiv;
}

export const createSensorTemperatureElement = (tyreArrayIndex, axleSensors) => {
    
    let sensorTemperatureDiv = document.createElement('div');  
    let sensorvaluesdivclasses = ['sensorvalues'];
    if (axleSensors[tyreArrayIndex].temperatureLabelColour != 'green')
        sensorvaluesdivclasses = ['sensorvalues', `bg-${axleSensors[tyreArrayIndex].temperatureLabelColour}`];

    let temperature = axleSensors[tyreArrayIndex].temperatureValue;
    // if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDateTemperatureValue) {
    //     sensorvaluesdivclasses = ['sensorvalues', axleSensors[tyreArrayIndex].selectedDateTemperatureLabelColour];
    //     temperature = axleSensors[tyreArrayIndex].selectedDateTemperatureValue;
    // }
    sensorTemperatureDiv.classList.add(...sensorvaluesdivclasses);
    sensorTemperatureDiv.innerHTML = temperature + " &#x2103";    

    return sensorTemperatureDiv;
}

export const createSensorVoltageElement = (tyreArrayIndex, axleSensors) => {
        
    let sensorVoltageDiv = document.createElement('div');    
    let sensorvaluesdivclasses = ['sensorvalues'];
    if (axleSensors[tyreArrayIndex].voltageLabelColour != 'green')
        sensorvaluesdivclasses = ['sensorvalues', `bg-${axleSensors[tyreArrayIndex].voltageLabelColour}`];

    let voltage = axleSensors[tyreArrayIndex].voltageValue;
    // if (global.sensorValuesDiagramDateSelected && axleSensors[tyreArrayIndex].selectedDateVoltageValue) {
    //     sensorvaluesdivclasses = ['sensorvalues', axleSensors[tyreArrayIndex].selectedDateVoltageLabelColour];
    //     voltage = axleSensors[tyreArrayIndex].selectedDateVoltageValue;
    // }
    sensorVoltageDiv.classList.add(...sensorvaluesdivclasses);
    sensorVoltageDiv.innerHTML = voltage + " V";

    return sensorVoltageDiv;
}

export const createSensorNoSignalElement = (tyreArrayIndex, axleSensors) => {

    let sensorNoSignalDiv = document.createElement('div');
    // let sensorvaluesdivclasses = ['sensorvalues', `bg-${axleSensors[tyreArrayIndex].noSignalLabelColour}`];    
    // if (global.sensorValuesDiagramDateSelected) {
    //     sensorvaluesdivclasses = ['sensorvalues', axleSensors[tyreArrayIndex].selectedDateNoSignalLabelColour];
    // }
    sensorNoSignalDiv.classList.add(...sensorvaluesdivclasses);
    sensorNoSignalDiv.innerText = "No Signal";

    return sensorNoSignalDiv;
}

export const createTrailerHeader = (diagram, unitSensors, trailerName) => {

    const trailerHeader = document.createElement('div');    

    const header = document.createElement('h6');
    const headerLabel = document.createElement('div');    

    const linkIcon = document.createElement('svg');
    linkIcon.className = 'linkicon';    
    const headerText = document.createElement('span');

    headerLabel.appendChild(linkIcon);
    headerLabel.appendChild(headerText);

    if (diagram === 'sv' || diagram === 'r') {
        trailerHeader.className = 'unitbox';
        headerText.id = `${diagram}trailer${trailerName}`;
        headerText.innerText = `Trailer ${trailerName}`;
    } else {
        trailerHeader.className = 'unitboxedit';
        headerText.id = `${diagram}trailer${trailerName}`;
        headerText.innerText = `Trailer ${trailerName}`;

        const tooltipText = document.createElement('span');
        tooltipText.className = 'tooltiptext';
        tooltipText.innerText = "EDIT TRAILER NAME";
        headerLabel.appendChild(tooltipText);
        headerLabel.className = 'edittrailername';
        headerLabel.onclick = function (e) {
            e.stopImmediatePropagation();
            openEditTrailerForm(unitSensors, trailerName);
        };
    }

    header.appendChild(headerLabel);
    trailerHeader.appendChild(header);

    return trailerHeader;
}

const createSpareTyresHeader = () => {

    const spareTyresHeader = document.createElement('div');
    spareTyresHeader.className = 'unitbox';

    const header = document.createElement('h6');
    //const headerLabel = document.createElement('div');
    //headerLabel.className = 'edittrailername';

    //const headerText = document.createElement('span');
    header.id = "sparetyres";
    header.innerText = "Spare Tyres";
    //headerLabel.appendChild(headerText);
    //header.appendChild(headerLabel);
    spareTyresHeader.appendChild(header);

    return spareTyresHeader;
}



